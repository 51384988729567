import { Coordinate } from "~/subjectProperty/domain/Coordinate";

export function getBoundsOfPoints(points: Coordinate[]) {
  let minLat = Infinity,
    maxLat = -Infinity,
    minLng = Infinity,
    maxLng = -Infinity;

  for (const record of points) {
    minLat = Math.min(minLat, record.lat);
    maxLat = Math.max(maxLat, record.lat);
    minLng = Math.min(minLng, record.lng);
    maxLng = Math.max(maxLng, record.lng);
  }

  return { minLat, maxLat, minLng, maxLng };
}

import { CustomClusterDefinition } from "./CustomClusterDefinition";

export const enum SessionCustomClusterType {
  Core = "core",
  Extra = "extra",
  Zipcode = "zipcode",
  Generated = "generated",
}

export interface SessionCustomCluster {
  id: string;
  type: SessionCustomClusterType;
  definition: CustomClusterDefinition;
}

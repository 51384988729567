import { Coordinate } from "~/subjectProperty/domain/Coordinate";
import { getBoundsOfPoints } from "./getBoundsOfPoints";

export function createPointsBoundFromProperties(
  points: Coordinate[],
): Coordinate[] {
  const { minLat, minLng, maxLat, maxLng } = getBoundsOfPoints(points);

  return [
    { lat: minLat, lng: minLng },
    { lat: minLat, lng: maxLng },
    { lat: maxLat, lng: maxLng },
    { lat: maxLat, lng: minLng },
  ];
}

import { atom } from "jotai";

import { PropertyListingFeature } from "../../propertyListing/domain/PropertyListing";
import { AdjustmentFeature } from "../domain/featureList";

const _featureUnitValueOverridesAtom = atom<
  Partial<Record<PropertyListingFeature, number | undefined>>
>({});

export const featureUnitValueOverridesAtom = atom((get) =>
  get(_featureUnitValueOverridesAtom),
);

export const overrideFeatureUnitValueAtom = atom(
  null,
  (get, set, feature: AdjustmentFeature, overrideValue: number | undefined) => {
    const previous = get(_featureUnitValueOverridesAtom);
    set(_featureUnitValueOverridesAtom, {
      ...previous,
      [feature]: overrideValue,
    });
  },
);

export const clearFeatureUnitValuesAtom = atom(null, (_get, set) => {
  set(_featureUnitValueOverridesAtom, {});
});

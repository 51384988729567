import { useAtomValue } from "jotai";

import { selectedCustomClusterIdsAtom } from "../state/selectedCustomClustersState";
import { useCustomClusters } from "./useCustomClusters";

export function useSelectedCustomClusters() {
  const customClusters = useCustomClusters();
  const selectedCustomClusterIds = useAtomValue(selectedCustomClusterIdsAtom);

  return customClusters.filter((cluster) =>
    selectedCustomClusterIds.includes(cluster.id),
  );
}

export function useSelectedCustomClusterIds() {
  const selectedCustomClusterIds = useAtomValue(selectedCustomClusterIdsAtom);

  return selectedCustomClusterIds;
}

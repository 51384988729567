import {
  CustomClusterBackendFilterOrOperator,
  CustomClusterBackendOperator,
} from "~/customClusters/domain/CustomClusterBackend";

export function andOperator(
  filters: CustomClusterBackendFilterOrOperator[],
): CustomClusterBackendOperator {
  return {
    filter_type: "Operation",
    logical_operator: "AND",
    filters: filters,
  };
}

export function orOperator(
  filters: CustomClusterBackendFilterOrOperator[],
): CustomClusterBackendOperator {
  return {
    filter_type: "Operation",
    logical_operator: "OR",
    filters: filters,
  };
}

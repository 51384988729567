import { atom, useAtom } from "jotai";

interface ExportState {
  exportIsTriggered: boolean;
  modalPDFIsOpen: boolean;
  exportData: ExportDataType;
}

interface ExportDataType {
  address: string;
  lat: number;
  lon: number;
  zoom: number;
}

const initialExportState: ExportState = {
  exportIsTriggered: false,
  modalPDFIsOpen: false,
  exportData: {
    address: "",
    lat: 0,
    lon: 0,
    zoom: 0,
  },
};

const exportStateAtom = atom<ExportState>(initialExportState);

export function useExportState() {
  const [state, setState] = useAtom(exportStateAtom);

  const setExportIsTriggered = (value: boolean) =>
    setState((prev) => ({ ...prev, exportIsTriggered: value }));

  const setModalPDFIsOpen = (value: boolean) =>
    setState((prev) => ({ ...prev, modalPDFIsOpen: value }));

  const updateExportData = (newData: Partial<ExportDataType>) =>
    setState((prev) => ({
      ...prev,
      exportData: { ...prev.exportData, ...newData },
    }));

  return {
    updateExportData,
    setExportIsTriggered,
    setModalPDFIsOpen,
    exportIsTriggered: state.exportIsTriggered,
    modalPDFIsOpen: state.modalPDFIsOpen,
    exportData: state.exportData,
  };
}

import { Alert } from "@mui/material";
import BoxCenter from "src/modules/ui/components/BoxCenter";

export default function SubjectPropertyGateDefaultFallback() {
  return (
    <BoxCenter>
      <Alert severity="error">Subject property features must be defined</Alert>
    </BoxCenter>
  );
}

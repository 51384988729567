import { Stack } from "@mui/material";

import ExportButton from "../Export/ExportButton.client";

export default function BoundariesHeader() {
  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      <ExportButton />
    </Stack>
  );
}

import { domToDataUrl } from "modern-screenshot";

import { PDFImageObject } from "./createExportDocument";

interface Dimensions {
  width: number;
  height: number;
}

function scaleToFitBounds(
  originalWidth: number,
  originalHeight: number,
  maxWidth: number,
  maxHeight: number,
): Dimensions {
  const aspectRatio = originalWidth / originalHeight;

  let newWidth = originalWidth;
  let newHeight = originalHeight;

  if (originalWidth > maxWidth || originalHeight > maxHeight) {
    if (originalWidth / maxWidth > originalHeight / maxHeight) {
      newWidth = maxWidth;
      newHeight = maxWidth / aspectRatio;
    } else {
      newHeight = maxHeight;
      newWidth = maxHeight * aspectRatio;
    }
  }

  return { width: newWidth, height: newHeight };
}

export async function exportElements(clusterCount: number) {
  const maps: PDFImageObject[] = [];
  const mapElement = document.querySelector("div.main-map")?.parentElement
    ?.parentElement as HTMLElement;
  const scaledMapDimensions = scaleToFitBounds(
    mapElement.clientWidth,
    mapElement.clientHeight,
    525,
    clusterCount === 1 ? 399 : 400,
  );

  mapElement.classList.add("exporting_map");

  async function downloadMap() {
    const url = await domToDataUrl(mapElement);
    maps.push({
      type: "map",
      image: url,
      width: scaledMapDimensions.width,
      height: scaledMapDimensions.height,
    });
    mapElement.classList.remove("exporting_map");
  }

  await downloadMap();

  return maps;
}

import Gate from "src/modules/ui/components/Gate";

import SubjectPropertySelectionModal from "../SubjectProperty/SubjectPropertySelectionModal";
import SubjectPropertyGateDefaultFallback from "./SubjectPropertyGateDefaultFallback";
import { useSessionHasSubject } from "~/common/hooks/useSessionSubject";

interface TrueTractsSubjectPropertyGateProps {
  children: React.ReactNode;
  fallback?: React.ReactNode;
  displayModal?: boolean;
}

export default function SubjectPropertyGate({
  children,
  fallback = <SubjectPropertyGateDefaultFallback />,
  displayModal = true,
}: TrueTractsSubjectPropertyGateProps) {
  const sessionHasSubject = useSessionHasSubject();

  return (
    <Gate
      condition={sessionHasSubject}
      fallback={displayModal ? fallbackWithModal(fallback) : fallback}
    >
      {children}
    </Gate>
  );
}

const fallbackWithModal = (fallback: React.ReactNode) => (
  <>
    {fallback}
    <SubjectPropertySelectionModal open={true} />
  </>
);

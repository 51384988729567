/* eslint-disable complexity */
import { useParams } from "@remix-run/react";
import { useQueryClient, useSuspenseQuery } from "@tanstack/react-query";
import { formatISO } from "date-fns";
import { useEffect } from "react";

import { SessionSubjectProperty } from "../../common/domain/SessionDataTypes";
import { useGetSessionSubject } from "../../common/hooks/useSessionSubject";
import { PropertyListing } from "../../propertyListing/domain/PropertyListing";
import { useApiRegionMetadata } from "../api/regionMetadata";
import { SubjectPropertyParameters } from "../domain/SubjectPropertyParameters";

export const subjectPropertyFormParameters: (keyof SessionSubjectProperty)[] = [
  "gla",
  "basement_finished_sqft",
  "lot_sqft",
  "bedrooms",
  "baths_full",
  "baths_half",
  "year_built",
  "garage_spaces",
];

// TODO: This needs to take into account region
export const requiredSubjectPropertyParameters: (keyof SessionSubjectProperty)[] =
  [
    "gla",
    // "basement_finished_sqft",
    "lot_sqft",
    "bedrooms",
    "baths_full",
    "baths_half",
    "year_built",
    "garage_spaces",
    "latitude",
    "longitude",
    //"region",
  ];

export const useSubjectProperty = () => {
  const { data: subjectData } = useSuspenseQuery(useGetSessionSubject());
  const params = useParams();

  const updatedSubjectProperty = getUpdatedSubjectProperty(
    subjectData.subject_property,
  );
  const queryClient = useQueryClient();
  const { data: regionMetadata } = useSuspenseQuery(
    useApiRegionMetadata({
      latitude: subjectData.subject_property.latitude,
      longitude: subjectData.subject_property.longitude,
    }),
  );

  if (!params.session) {
    console.error("Session not found - useGetSessionSubject");
    throw new Error("Session not found - useGetSessionSubject");
  }

  const sessionId: string = params.session;

  useEffect(() => {
    if (regionMetadata && regionMetadata.region) {
      if (subjectData.subject_property.region != regionMetadata.region) {
        subjectData.subject_property.region = regionMetadata.region;
        queryClient.setQueryData(
          ["subject", sessionId, { parent: "sessions" }],
          (oldData) => (oldData ? { ...oldData, ...subjectData } : oldData),
        );
      }
    }
  }, [regionMetadata, subjectData.subject_property.region]);

  if (!regionMetadata) {
    throw new Error("Error loading region metadata");
  }

  const subjectProperty = getSubjectProperty(
    subjectData.subject_property,
    regionMetadata.region as string,
  );

  return {
    subjectProperty,
    updatedSubjectProperty,
  };
};

function getSubjectProperty(
  partialSubjectProperty: Partial<SessionSubjectProperty>,
  region: string | undefined,
): SubjectPropertyParameters {
  if (
    !isSubjectPropertyDefined(partialSubjectProperty) ||
    partialSubjectProperty.baths_full == null ||
    partialSubjectProperty.baths_half == null ||
    region == null
  ) {
    throw new Error(
      "Attempted to get subject property without all parameters defined",
    );
  }

  // Force a cast to SubjectPropertyParameters as we only reach this code when the entire subject
  // property is well defined
  const subjectProperty: SubjectPropertyParameters = {
    ...partialSubjectProperty,
    address: 0,
    bathrooms:
      partialSubjectProperty.baths_full +
      partialSubjectProperty.baths_half * 0.5,
    region: region,
  } as SubjectPropertyParameters;

  return subjectProperty;
}

export function isSubjectPropertyDefined(
  params: Partial<SessionSubjectProperty | undefined>,
) {
  return !requiredSubjectPropertyParameters.some(
    (param) => params && params[param] == null,
  );
}

function getUpdatedSubjectProperty(
  subjectProperty: SessionSubjectProperty,
): PropertyListing {
  let address = undefined;

  if (
    subjectProperty.address.street_address != null &&
    subjectProperty.address.city != null &&
    subjectProperty.address.state != null &&
    subjectProperty.address.zipcode != null
  ) {
    address = {
      street_address: subjectProperty.address.street_address,
      city: subjectProperty.address.city,
      state: subjectProperty.address.state,
      zipcode: subjectProperty.address.zipcode,
    };
  }

  return {
    mls_id: {
      mls_name: "SubjectProperty",
      listing_number: "0",
    },
    media: {
      main_photo: "",
    },
    address: address,
    features: {
      sold_price: 0,
      basement_finished_sqft: subjectProperty.basement_finished_sqft,
      bathrooms: subjectProperty.baths_full + subjectProperty.baths_half * 0.5,
      bedrooms: subjectProperty.bedrooms,
      garage_spaces: subjectProperty.garage_spaces,
      gla: subjectProperty.gla,
      lot_sqft: subjectProperty.lot_sqft,
      contract_date: formatISO(new Date()),
      year_built: subjectProperty.year_built,
    },
    latitude: subjectProperty.latitude,
    longitude: subjectProperty.longitude,
  };
}

/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */

// TODO remove createSampleCustomCluster and the above eslint rules
// after development

import { Alert } from "@mui/material";
import { useSetAtom } from "jotai";
import { ClientOnly } from "remix-utils/client-only";
import BoxCenter from "src/modules/ui/components/BoxCenter";

import { createClusterAtom } from "~/customClusters/state/customClustersState";
import { useSubjectProperty } from "~/subjectProperty/state/useSubjectProperty";

interface DefinedCustomClustersGateDefaultFallbackProps {
  sectionName?: string;
}

export default function DefinedCustomClustersGateDefaultFallback({
  sectionName = "this section",
}: DefinedCustomClustersGateDefaultFallbackProps) {
  const { subjectProperty } = useSubjectProperty();
  const createCluster = useSetAtom(createClusterAtom);

  const createSampleCustomCluster = () => {
    const { latitude, longitude } = subjectProperty;
    const numberOfPoints = 30;
    const R = 0.02;
    const points = [];

    for (let i = 0; i < numberOfPoints; i++) {
      const angle = (i * 2 * Math.PI) / numberOfPoints;
      const lat = latitude + R * Math.cos(angle);
      const lng =
        longitude +
        (R * Math.sin(angle)) / Math.cos(latitude * (Math.PI / 180));

      points.push({ lat, lng });
    }

    createCluster({
      points: points,
      filters: [],
    });
  };

  return (
    <ClientOnly>
      {() => (
        <BoxCenter>
          <Alert severity="error">
            At least one custom cluster must be defined before accessing{" "}
            {sectionName}.{" "}
            <a href="#" onClick={createSampleCustomCluster}>
              Create sample custom cluster.
            </a>
          </Alert>
        </BoxCenter>
      )}
    </ClientOnly>
  );
}

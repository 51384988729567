/* eslint-disable complexity */
import { Content } from "pdfmake/interfaces";

import { formatNumber } from "~/common/util/formatters";
import { CustomClusterFilter } from "~/customClusters/domain/CustomClusterFilter";
import { PropertyListingFeature } from "~/propertyListing/domain/PropertyListing";
import { createParagraph, createUnorderedList } from "./generators";

const formatFilterValue = (filter: CustomClusterFilter): string | null => {
  if (!filter || filter.range.type !== "numeric") return null;

  const { from, to } = filter.range;
  const formatValue = filter.feature === "year_built" ? String : formatNumber;

  if (from && to) return `${formatValue(from)} - ${formatValue(to)}`;
  if (from) return `≥ ${formatValue(from)}`;
  if (to) return `≤ ${formatValue(to)}`;
  return null;
};

export const createFiltersList = (
  filters: CustomClusterFilter[],
): Content[] => {
  const filterMappings: Partial<Record<PropertyListingFeature, string>> = {
    gla: "GLA",
    basement_finished_sqft: "Basement Finished",
    lot_sqft: "Lot Size",
    bedrooms: "Bedrooms",
    bathrooms: "Baths",
    year_built: "Year Built",
    garage_spaces: "Garage Spaces",
    sold_price: "Sold Price",
  };

  const formattedFilters = filters
    .map((filter) => {
      const value = formatFilterValue(filter);
      if (!value) return null;

      const label = filterMappings[filter.feature as PropertyListingFeature];
      const unit = ["gla", "basement_finished_sqft", "lot_sqft"].includes(
        filter.feature,
      )
        ? " sqft"
        : "";
      return `${label}: ${value}${unit}`;
    })
    .filter(Boolean);

  return [
    createParagraph("Feature filters applied: "),
    createUnorderedList(
      formattedFilters
        ? formattedFilters.filter((filter): filter is string => filter !== null)
        : [],
    ),
  ];
};

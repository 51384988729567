import { usePlacesWidget } from "react-google-autocomplete";

import { SubjectPropertySelectionAddressFormOutput } from "../components/SubjectProperty/SubjectPropertySelectionAddressFormField";

export function usePlacesAutocompleteWithCallback(
  onAddressChange: (
    inputAddress: string,
    output: SubjectPropertySelectionAddressFormOutput,
  ) => void,
) {
  return usePlacesWidget({
    apiKey: import.meta.env.PUBLIC_GOOGLE_MAPS_KEY as string,
    options: {
      types: ["address"],
      componentRestrictions: { country: "us" },
    },
    onPlaceSelected: (place: google.maps.places.PlaceResult) => {
      if (
        place?.geometry?.location?.lat == null ||
        place?.geometry?.location?.lng == null ||
        place?.formatted_address == null
      ) {
        return;
      }

      onAddressChange(place?.formatted_address, {
        latitude: place.geometry.location.lat(),
        longitude: place.geometry.location.lng(),
      });
    },
  });
}

import { useAtomValue } from "jotai";

import { customClustersAtom } from "../state/customClustersState";
import { useHeatmapCustomCluster } from "./useHeatmapCustomCluster";
import { useZipcodeCustomCluster } from "./useZipcodeCustomCluster";

export function useCustomClusters() {
  const customClusters = useAtomValue(customClustersAtom);

  const heatmapCustomCluster = useHeatmapCustomCluster();
  const zipcodeCustomCluster = useZipcodeCustomCluster();

  const newCustomClusters = [heatmapCustomCluster, zipcodeCustomCluster].filter(
    (x) => x != null,
  );

  return customClusters.concat(newCustomClusters);
}

import { format as formatDate } from "date-fns";

import { formatCurrency, formatNumber } from "~/common/util/formatters";

const convertToNumber = (value: string | number): number => {
  if (typeof value === "string") {
    const numValue = parseFloat(value);

    if (isNaN(numValue)) {
      throw new Error(`Could not convert value ${value} into a number`);
    }

    return numValue;
  }

  return value;
};

export const formatMap: Record<string, (value: string | number) => string> = {
  gla: (value) => formatNumber(convertToNumber(value)),
  lot_sqft: (value) => formatNumber(convertToNumber(value)),
  contract_date_iso: (value) => formatDate(new Date(value), "yyyy-MM-dd"),
  sold_price: (value) => formatCurrency(convertToNumber(value)),
  adjusted_price: (value) => formatCurrency(convertToNumber(value)),
  price_residual: (value) => formatCurrency(convertToNumber(value)),
};

export function formatValues(value: string | number, feature: string) {
  return feature in formatMap ? formatMap[feature](value) : value;
}

export const featureLabelMap: Record<string, string> = {
  listing_number: "MLS Listing Number",
  tag: "Cluster Number",
  latitude: "Latitude",
  longitude: "Longitude",
  gla: "GLA",
  lot_sqft: "Lot Sqft",
  bedrooms: "Bedrooms",
  bathrooms: "Bathrooms",
  full_bathrooms: "Full Bathrooms",
  half_bathrooms: "Half Bathrooms",
  garage_spaces: "Garage Spaces",
  year_built: "Year Built",
  pool: "Pool",
  carport_spaces: "Carport Spaces",
  stories: "Stories",
  fireplace_count: "Fireplace Count",
  basement_finished_sqft: "Basement Finished Sqft",
  basement_baths_total: "Basement Baths",
  basement_beds: "Basement Beds",
  contract_date: "Days Since Contract Date",
  contract_date_iso: "Contract Date",
  sold_price: "Raw Sale Price",
  adjusted_price: "Sale Prices Adjusted To Today",
  price_residual: "Price Residuals",
};

export function formatFeatureLabel(feature: string): string {
  return feature in featureLabelMap ? featureLabelMap[feature] : feature;
}

export const subjectPropertyModalFeatureLabelMap: Record<string, string> = {
  bedrooms: "Bedrooms (above grade only)",
  baths_full: "Full Bathrooms (above grade only)",
  baths_half: "Half Bathrooms (above grade only)",
};

export function formatSubjectPropertyModalFeatureLabel(
  feature: string,
): string {
  return feature in subjectPropertyModalFeatureLabelMap
    ? subjectPropertyModalFeatureLabelMap[feature]
    : formatFeatureLabel(feature);
}

import { useSearchParams } from "@remix-run/react";
import { axiosClient } from "src/modules/api/AxiosClient";
import { useUserToken } from "src/modules/auth/hooks/useUserToken";

import { PropertyListingFeatureWithDerived } from "../../propertyListing/domain/PropertyListing";

interface FindAreaFnOptions {
  latitude: number;
  longitude: number;
  regionOverride?: string | null;
}

interface ConfigRawResult {
  sanity_filters: Record<
    string,
    {
      max: number;
      min: number;
    }
  >;
  adjustment_predictor: string;
  columns_to_use_basic: string[];
  columns_to_use_additional: string[];
  features_not_for_gaussian: string[];
  ignored_features: string[];
}

interface ConfigResult {
  columns_to_use_basic: PropertyListingFeatureWithDerived[];
  columns_to_use_additional: PropertyListingFeatureWithDerived[];
  features_not_for_gaussian: PropertyListingFeatureWithDerived[];
  ignored_features: PropertyListingFeatureWithDerived[];
}

export interface ValidRegionResult {
  isValid: true;
  region: string;
  metadata: ConfigResult;
}

export interface InvalidRegionResult {
  isValid: false;
  region: null;
  metadata: null;
}

const regionMetadataFn = async (
  options: FindAreaFnOptions | undefined,
  token: string,
): Promise<ValidRegionResult | InvalidRegionResult | undefined> => {
  if (!options) {
    return {
      isValid: false,
      region: null,
      metadata: null,
    };
  }

  let region: string;

  if (options.regionOverride != null) {
    region = options.regionOverride;
  } else {
    const { data: findAreaData } = await axiosClient.get<{ result: string[] }>(
      `/findarea/${options.longitude}/${options.latitude}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    if (findAreaData == null || findAreaData.result.length == 0) {
      return {
        isValid: false,
        region: null,
        metadata: null,
      };
    }

    region = findAreaData.result[0];
  }

  const { data: configData } = await axiosClient.get<string>(
    `/config/${region}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

  const configDataParsed = JSON.parse(configData) as ConfigRawResult;
  const configDataValidated = validateConfigData(configDataParsed);

  return {
    isValid: true,
    region,
    metadata: configDataValidated,
  };
};

export const useApiRegionMetadata = (
  options?: Omit<FindAreaFnOptions, "regionOverride">,
) => {
  const token = useUserToken();
  const [searchParams, _] = useSearchParams();

  const optionsWithOverride = optionsWithRegionOverride(options, searchParams);

  return {
    queryKey: ["regionMetadata", optionsWithOverride],
    queryFn: () => regionMetadataFn(optionsWithOverride, token),
  };
};

function validateConfigData(configData: ConfigRawResult): ConfigResult {
  return {
    columns_to_use_basic: convertFeatureList(configData.columns_to_use_basic),
    columns_to_use_additional: convertFeatureList(
      configData.columns_to_use_additional,
    ),
    features_not_for_gaussian: convertFeatureList(
      configData.features_not_for_gaussian,
    ),
    ignored_features: convertFeatureList(configData.ignored_features),
  };
}

function convertFeatureList(
  featureList: string[],
): PropertyListingFeatureWithDerived[] {
  return featureList.map((originalFeature) => {
    // TODO
    // We can't enforce types at runtime, this may leak objects through
    return originalFeature as PropertyListingFeatureWithDerived;
  });
}

function optionsWithRegionOverride(
  options: Omit<FindAreaFnOptions, "regionOverride"> | undefined,
  searchParams: URLSearchParams,
): FindAreaFnOptions | undefined {
  const regionOverride = searchParams.get("region");

  if (regionOverride == null || options == null) {
    return options;
  }

  return {
    ...options,
    regionOverride,
  };
}

import { useQuery } from "@tanstack/react-query";
import { useAtomValue } from "jotai";

import { useApiHeatmap } from "~/boundaries/api/heatmap";
import { averageHeatmapRecords } from "~/boundaries/components/Map/MapView/TileLayer/Heatmap/HeatmapLayer/logic/averageHeatmapRecords";
import { convertPercentagesToRelativeOfSubjectProperty } from "~/boundaries/components/Map/MapView/TileLayer/Heatmap/HeatmapLayer/logic/convertPercentagesToRelativeOfSubjectProperty";
import {
  averagedHeatmapTargetResolutionAtom,
  heatmapSettingsAtom,
} from "~/boundaries/state/heatmapSettingsState";
import { useSubjectProperty } from "~/subjectProperty/state/useSubjectProperty";
import { SessionCustomClusterType } from "../domain/SessionCustomCluster";
import { customClustersAtom } from "../state/customClustersState";

export function useHeatmapFromCoreCluster() {
  const { subjectProperty } = useSubjectProperty();
  const customClusters = useAtomValue(customClustersAtom);

  const coreCustomCluster = customClusters.find(
    (cluster) => cluster.type === SessionCustomClusterType.Core,
  );

  const averagedHeatmapTargetResolution = useAtomValue(
    averagedHeatmapTargetResolutionAtom,
  );
  const heatmapSettings = useAtomValue(heatmapSettingsAtom);

  const heatmapOptions = {
    customCluster: coreCustomCluster?.definition ?? undefined,
    ...heatmapSettings,
  };

  const { data: heatmapData } = useQuery(
    useApiHeatmap(heatmapOptions, coreCustomCluster != null),
  );

  const { data: averagedHeatmapData } = useQuery({
    enabled: heatmapData != null && coreCustomCluster != null,
    queryKey: [
      "averagedHeatmapData",
      heatmapOptions,
      averagedHeatmapTargetResolution,
    ],
    queryFn: () => {
      if (heatmapData == null || coreCustomCluster == null) {
        return null;
      }

      const adjustedHeatmapRecords =
        convertPercentagesToRelativeOfSubjectProperty(
          heatmapData.heatmap,
          subjectProperty.latitude,
          subjectProperty.longitude,
        );

      const averagedRecords = averageHeatmapRecords(
        adjustedHeatmapRecords,
        averagedHeatmapTargetResolution,
        heatmapData.properties,
      );

      return {
        adjustedHeatmapRecords,
        averagedRecords,
      };
    },
  });

  return {
    heatmapData,
    adjustedHeatmapRecords: averagedHeatmapData?.adjustedHeatmapRecords,
    averagedHeatmapData: averagedHeatmapData?.averagedRecords,
    averagedHeatmapTargetResolution,
    coreCustomCluster,
    heatmapOptions,
  };
}

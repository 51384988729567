import { Chip } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useAtomValue } from "jotai";

import {
  InvalidRegionResult,
  useApiRegionMetadata,
  ValidRegionResult,
} from "../../api/regionMetadata";
import { readSubjectPropertyAtom } from "../../state/subjectPropertyState";
import { regionQueryOptionsFromModalState } from "../../utils/regionQueryOptionsFromModalState";
import { SubjectProperty } from "~/common/hooks/useSessionData";

const successMessage = "Address is in a county that TrueTracts covers";
const errorMessage = (
  <>
    Address is in a county that TrueTracts doesn&#39;t cover. For a list of
    counties that TrueTracts currently covers, click{" "}
    <a
      target="_blank"
      href="https://docs.google.com/spreadsheets/d/165m7RD1Xwf-VtwEZl8cuBOqVdpLmrUFzXtgm8eKAtG8/edit?gid=1050228818#gid=1050228818"
      rel="noreferrer"
      style={{
        color: "white",
      }}
    >
      here
    </a>
  </>
);

interface SubjectPropertySelectionModalSubmitStatusProps {
  changes: SubjectProperty;
}

export default function SubjectPropertySelectionModalSubmitStatus({
  changes,
}: SubjectPropertySelectionModalSubmitStatusProps) {
  const subjectProperty = useAtomValue(readSubjectPropertyAtom);

  const { data: regionMetadata, isLoading: regionMetadataIsLoading } = useQuery(
    useApiRegionMetadata(
      regionQueryOptionsFromModalState(changes, subjectProperty),
    ),
  );

  const result = getResultFromData(regionMetadata, regionMetadataIsLoading);

  if (!result) {
    return null;
  }

  return (
    <Chip
      sx={{
        height: "auto",
        "& .MuiChip-label": {
          display: "block",
          whiteSpace: "normal",
          paddingTop: "0.5em",
          paddingBottom: "0.5em",
          textAlign: "center",
        },
      }}
      label={result.message}
      color={result.color}
    />
  );
}

function getResultFromData(
  regionMetadata: ValidRegionResult | InvalidRegionResult | undefined,
  regionMetadataIsLoading: boolean,
):
  | {
      message: React.ReactNode;
      color: "default" | "error" | "success";
    }
  | undefined {
  if (regionMetadataIsLoading) {
    return {
      message: "Loading...",
      color: "default",
    };
  }

  if (regionMetadata == null) {
    return undefined;
  }

  if (!regionMetadata.isValid) {
    return {
      message: errorMessage,
      color: "error",
    };
  }

  return {
    message: successMessage,
    color: "success",
  };
}

import { formatISO } from "date-fns";
import { axiosClient } from "src/modules/api/AxiosClient";
import { useUserToken } from "src/modules/auth/hooks/useUserToken";

import { filtersToBackendFilterPayload } from "~/customClusters/util/backend/filtersToBackendFilterPayload";
import { ZipcodeCustomClusterDefinition } from "../../customClusters/domain/ZipcodeCustomClusterDefinition";
import { PropertyListing } from "../domain/PropertyListing";
import {
  PropertyListingGetByIdsResponse,
  propertyListingGetByIdsResponseToPropertyListing,
} from "./types/PropertyListingGetByIdsResponse";

interface PropertyListingGetByZipcodeFnOptions {
  customCluster: ZipcodeCustomClusterDefinition;
}

export interface PropertyListingGetByZipcodeRawResult {
  property_listings: PropertyListingGetByIdsResponse[];
}

export interface PropertyListingGetByZipcodeResult {
  customClusterIds: string[];
  properties: PropertyListing[];
}

export const propertyListingGetByZipcodeFn = async (
  options: PropertyListingGetByZipcodeFnOptions,
  token: string,
): Promise<PropertyListingGetByZipcodeResult> => {
  const requestBody = {
    zipcode: options.customCluster.zipcode,
    effective_date: formatISO(new Date(), { representation: "date" }),
    filters: filtersToBackendFilterPayload(options.customCluster.filters),
  };

  const { data } = await axiosClient.post<PropertyListingGetByZipcodeRawResult>(
    `/property_listing/get_by_zipcode`,
    requestBody,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

  return {
    customClusterIds: ["zipcode"],
    properties: data.property_listings.map((x) =>
      propertyListingGetByIdsResponseToPropertyListing(x),
    ),
  };
};

export const useApiPropertyListingGetByZipcode = (
  customCluster: ZipcodeCustomClusterDefinition | undefined,
) => {
  const token = useUserToken();

  if (customCluster == null) {
    return {
      queryKey: ["propertyListingGetByZipcode"],
      queryFn: () => {
        return null;
      },
      enabled: false,
    };
  }

  const options = {
    customCluster,
  };

  return {
    queryKey: ["propertyListingGetByZipcode", options],
    queryFn: () => propertyListingGetByZipcodeFn(options, token),
  };
};

import { atom } from "jotai";
import cloneDeep from "lodash/cloneDeep";
import { v4 as uuidv4 } from "uuid";

import { Coordinate } from "../../subjectProperty/domain/Coordinate";
import { CustomClusterDefinition } from "../domain/CustomClusterDefinition";
import { CustomClusterFilter } from "../domain/CustomClusterFilter";
import {
  SessionCustomCluster,
  SessionCustomClusterType,
} from "../domain/SessionCustomCluster";
import {
  selectCustomClusterIdAtom,
  unselectCustomClusterIdAtom,
} from "./selectedCustomClustersState";

const _customClustersAtom = atom<SessionCustomCluster[]>([]);

export const customClustersAtom = atom((get) => get(_customClustersAtom));

export const createClusterAtom = atom(
  null,
  (get, set, definition: CustomClusterDefinition) => {
    const customClusters = get(_customClustersAtom);

    const type =
      customClusters.length > 0
        ? SessionCustomClusterType.Extra
        : SessionCustomClusterType.Core;

    const nextId = `${type}-${uuidv4()}`;

    const sessionCustomCluster: SessionCustomCluster = {
      id: nextId,
      type,
      definition,
    };

    let updatedCustomClusters = customClusters;

    if (type === SessionCustomClusterType.Core) {
      updatedCustomClusters = updateAllSecondaryCustomClustersWithFilter(
        definition.filters,
        customClusters,
      );
    }

    set(
      _customClustersAtom,
      updatedCustomClusters.concat([sessionCustomCluster]),
    );

    set(selectCustomClusterIdAtom, nextId);

    return nextId;
  },
);

export const updateClusterAtom = atom(
  null,
  (get, set, clusterId: string, definition: CustomClusterDefinition) => {
    const customClusters = get(_customClustersAtom);

    // [TF-411] Update all other custom clusters' filters when editing one
    let updatedCustomClusters = updateAllSecondaryCustomClustersWithFilter(
      definition.filters,
      customClusters,
    );

    updatedCustomClusters = updatedCustomClusters.map((cluster) => {
      if (cluster.id === clusterId) {
        const cloned = cloneDeep(cluster);
        cloned.definition = cloneDeep(definition);

        return cloned;
      }

      return cluster;
    });

    set(_customClustersAtom, updatedCustomClusters);
  },
);

export const updateCoreClusterPointsAtom = atom(
  null,
  (get, set, points: Coordinate[]) => {
    const customClusters = get(_customClustersAtom);

    const updatedCustomClusters = customClusters.map((cluster) => {
      if (cluster.type === SessionCustomClusterType.Core) {
        const cloned = cloneDeep(cluster);
        cloned.definition.points = cloneDeep(points);

        return cloned;
      }

      return cluster;
    });

    set(_customClustersAtom, updatedCustomClusters);
  },
);

export const deleteClusterAtom = atom(
  null,
  (get, set, clusterIdToDelete: string) => {
    const customClusters = cloneDeep(get(_customClustersAtom));

    const updatedCustomClusters = customClusters.filter(
      (cluster) => cluster.id != clusterIdToDelete,
    );

    set(unselectCustomClusterIdAtom, clusterIdToDelete);
    set(_customClustersAtom, updatedCustomClusters);
  },
);

export const deleteAllClustersAtom = atom(null, (get, set) => {
  set(_customClustersAtom, []);
});

function updateAllSecondaryCustomClustersWithFilter(
  filters: CustomClusterFilter[],
  existingCustomClusters: SessionCustomCluster[],
): SessionCustomCluster[] {
  return existingCustomClusters.map((customCluster) => {
    if (customCluster.type === SessionCustomClusterType.Extra) {
      const updatedCustomCluster = cloneDeep(customCluster);
      updatedCustomCluster.definition.filters = cloneDeep(filters);

      return updatedCustomCluster;
    }

    return customCluster;
  });
}

import { useContext } from "react";

import { UserContext } from "../components/UserProvider";

export const useUserToken = () => {
  const userContext = useContext(UserContext);

  if (!userContext.user) {
    throw new Error("Attempted to obtain user token from undefined user");
  }

  return userContext.user.accessToken;
};

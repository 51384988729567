import { MlsId } from "../../domain/MlsId";
import {
  PropertyAddress,
  PropertyListing,
  PropertyListingMedia,
} from "../../domain/PropertyListing";

export interface PropertyListingGetByIdsResponse {
  mls_id: MlsId;
  address: PropertyAddress;
  media?: PropertyListingMedia;
  latitude: number;
  longitude: number;
  contract_date: string;
  concessions_dollars: number;
  year_built: number;
  sold_price: number;
  sale_date: string;
  lot_sqft: number;
  gla: number;
  bedrooms: number;
  bathrooms: number;
  baths_full: number;
  baths_half: number;
  basement_finished_sqft: number;
  garage_spaces: number;
  pool: number;
  carport_spaces: number;
  stories: number;
  fireplace_count: number;
  basement_baths_total: number;
  basement_beds: number;
  basement_exists: number;
}

export function propertyListingGetByIdsResponseToPropertyListing(
  data: PropertyListingGetByIdsResponse,
): PropertyListing {
  return {
    mls_id: data.mls_id,
    latitude: data.latitude,
    longitude: data.longitude,
    features: {
      basement_baths_total: data.basement_baths_total,
      basement_beds: data.basement_beds,
      basement_finished_sqft: data.basement_finished_sqft,
      basement_exists: data.basement_exists,
      bathrooms: data.bathrooms,
      baths_full: data.baths_full,
      baths_half: data.baths_half,
      bedrooms: data.bedrooms,
      carport_spaces: data.carport_spaces,
      concessions_dollars: data.concessions_dollars,
      contract_date: data.contract_date,
      fireplace_count: data.fireplace_count,
      garage_spaces: data.garage_spaces,
      gla: data.gla,
      lot_sqft: data.lot_sqft,
      pool: data.pool,
      sale_date: data.sale_date,
      sold_price: data.sold_price,
      stories: data.stories,
      year_built: data.year_built,
    },
    media: {
      main_photo: data.media?.main_photo,
    },
    address: data.address,
  };
}

import { atom } from "jotai";

import SiteConfig from "~/configuration/SiteConfig";

export const heatmapResolutionAtom = atom(
  SiteConfig.modules.boundaries.heatmap.resolution,
);
export const averagedHeatmapTargetResolutionAtom = atom(
  SiteConfig.modules.boundaries.heatmap.resolution,
);

export const heatmapFilterFactorAtom = atom(
  SiteConfig.modules.boundaries.heatmap.filterFactor,
);
export const heatmapDesiredHomeSalesAtom = atom(
  SiteConfig.modules.boundaries.heatmap.desiredHomeSales,
);

export const heatmapSettingsAtom = atom((get) => {
  return {
    resolution: get(heatmapResolutionAtom),
    filterFactor: get(heatmapFilterFactorAtom),
    desiredHomeSales: get(heatmapDesiredHomeSalesAtom),
  };
});

import { atom } from "jotai";

import { MapLayer } from "../domain/MapLayer";

export interface MapLayerInState extends Omit<MapLayer, "component"> {
  active: boolean;
}

export const mapLayersAtom = atom<MapLayerInState[]>([]);

export const setMapLayerAtom = atom(
  null,
  (get, set, name: string, active: boolean) => {
    const previousState = get(mapLayersAtom);

    set(
      mapLayersAtom,
      previousState.map((layer) => {
        if (layer.name === name) {
          return {
            ...layer,
            active,
          };
        }

        return layer;
      }),
    );
  },
);

export const setMapLayersToDefaultValuesAtom = atom(null, (get, set) => {
  const previousState = get(mapLayersAtom);
  set(
    mapLayersAtom,
    previousState.map((layer) => ({
      ...layer,
      active: layer.activeByDefault,
    })),
  );
});

export function parsePropertyList(propertyList: string): string[] {
  if (propertyList.trim() === "") {
    return [];
  } else {
    const newAdjustmentMlsIds = [
      ...new Set(
        propertyList
          .split(/\n|,/)
          .map((id) => id.trim())
          .filter((id) => id != ""),
      ),
    ];
    return newAdjustmentMlsIds;
  }
}

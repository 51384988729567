import { Stack } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useAtomValue } from "jotai";

import {
  InvalidRegionResult,
  useApiRegionMetadata,
  ValidRegionResult,
} from "../../api/regionMetadata";
import { readSubjectPropertyAtom } from "../../state/subjectPropertyState";
import { subjectPropertyFormParameters } from "../../state/useSubjectProperty";
import { regionQueryOptionsFromModalState } from "../../utils/regionQueryOptionsFromModalState";
import SubjectPropertySelectionFormFieldSelector from "./SubjectPropertySelectionFormFieldSelector";
import { SubjectPropertyUrlParameters } from "~/subjectProperty/domain/SubjectPropertyParameters";
import {
  SubjectProperty,
  SubjectPropertyKeys,
} from "~/common/hooks/useSessionData";
import { SessionSubjectProperty } from "~/common/domain/SessionDataTypes";

interface SubjectPropertySelectionFormProps {
  changes: SubjectProperty;
  addChange: (feature: SubjectPropertyKeys, newValue: string | number) => void;
}

export default function SubjectPropertySelectionForm({
  changes,
  addChange,
}: SubjectPropertySelectionFormProps) {
  const readSubjectProperty = useAtomValue(readSubjectPropertyAtom);

  const onChange = (newValue: number, feature: SubjectPropertyKeys) => {
    addChange(feature, newValue);
  };

  const { data: regionMetadata } = useQuery(
    useApiRegionMetadata(
      regionQueryOptionsFromModalState(changes, readSubjectProperty),
    ),
  );

  const availableSubjectPropertyFormParameters =
    calculateSubjectPropertyFormParameters(regionMetadata);

  return (
    <Stack spacing={2} marginY={4}>
      {availableSubjectPropertyFormParameters.map((param) => (
        <SubjectPropertySelectionFormFieldSelector
          key={param}
          feature={param}
          currentValue={changes[param] ?? readSubjectProperty[param] ?? ""}
          onChange={onChange}
        />
      ))}
    </Stack>
  );
}

const requiredFeatures: (keyof SubjectPropertyUrlParameters)[] = [
  "longitude",
  "latitude",
];

function calculateSubjectPropertyFormParameters(
  regionMetadata: ValidRegionResult | InvalidRegionResult | undefined,
): (keyof SessionSubjectProperty)[] {
  if (regionMetadata == null) {
    return [];
  }
  if (!regionMetadata.isValid) {
    return requiredFeatures as (keyof SessionSubjectProperty)[];
  }

  return subjectPropertyFormParameters;
}

import { Gite } from "@mui/icons-material";

import BoundariesHeader from "~/boundaries/components/Header/BoundariesHeader";
import { ValuationProcessStep } from "~/common/state/valuationProcessState";
import CompRecommendationHeader from "~/compRecommendation/components/Header/CompRecommendationHeader";
import { SiteConfiguration } from "./domain/SiteConfiguration";
import { internalNavigationButtons } from "./internalNavigationButtons";

export default {
  header: {
    icon: <Gite />,
    title: "TrueTracts",
    components: {
      [ValuationProcessStep.Sessions]: null,
      [ValuationProcessStep.Boundaries]: <BoundariesHeader />,
      [ValuationProcessStep.CompRecommendation]: <CompRecommendationHeader />,
      [ValuationProcessStep.CompRec]: null,
      [ValuationProcessStep.MarketAnalysis]: null,
      [ValuationProcessStep.Adjustments]: null,
      [ValuationProcessStep.Reconciliation]: null,
      [ValuationProcessStep.Export]: null,
    },
  },
  navBar: {
    buttons: internalNavigationButtons,
  },
  body: {
    componentOverrides: {
      [ValuationProcessStep.Sessions]: null,
      [ValuationProcessStep.Boundaries]: null,
      [ValuationProcessStep.CompRecommendation]: null,
      [ValuationProcessStep.CompRec]: null,
      [ValuationProcessStep.MarketAnalysis]: null,
      [ValuationProcessStep.Adjustments]: null,
      [ValuationProcessStep.Reconciliation]: null,
      [ValuationProcessStep.Export]: null,
    },
  },
  modules: {
    boundaries: {
      showResidualData: true,
      showZipcodeOverlay: true,
      heatmap: {
        resolution: 200,
        filterFactor: 6,
        desiredHomeSales: 15000,
      },
    },
  },
} satisfies Readonly<SiteConfiguration>;

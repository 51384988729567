import { Coordinate } from "../../subjectProperty/domain/Coordinate";

export function coordinateToPolygonFormat(points: Coordinate[]): string {
  const fixedPoints = createClosedPolygon(points);
  return `POLYGON((${fixedPoints.map((point) => `${point.lng} ${point.lat}`).join(",")}))`;
}

export function createClosedPolygon(points: Coordinate[]): Coordinate[] {
  const closedPoints = [...points];
  // append the first point to the array, so that it is the last one
  closedPoints.push(points[0]);
  return closedPoints;
}

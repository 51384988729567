import { useQuery, useSuspenseQuery } from "@tanstack/react-query";
import { useAtomValue } from "jotai";

import { createPointsBoundFromProperties } from "~/boundaries/components/Map/MapView/TileLayer/Heatmap/HeatmapLayer/logic/createPointsBoundFromProperties";
import { useApiPropertyListingGetInPolygon } from "~/propertyListing/api/propertyListingGetInPolygon";
import {
  SessionCustomCluster,
  SessionCustomClusterType,
} from "../domain/SessionCustomCluster";
import { generatedClusterConstraintAtom } from "../state/generatedClusterConstraintState";
import { customClusterFilterFromProperties } from "../util/customClusterFilterFromProperties";
import { useHeatmapFromCoreCluster } from "./useHeatmapFromCoreCluster";

export function useHeatmapCustomCluster() {
  const {
    heatmapData,
    averagedHeatmapData,
    averagedHeatmapTargetResolution,
    coreCustomCluster,
    heatmapOptions,
  } = useHeatmapFromCoreCluster();
  const { data: coreCustomClusterProperties } = useSuspenseQuery(
    useApiPropertyListingGetInPolygon([coreCustomCluster]),
  );

  const generatedClusterConstraint = useAtomValue(
    generatedClusterConstraintAtom,
  );

  const automatedCustomClusterQueryKey = {
    heatmapOptions,
    averagedHeatmapTargetResolution,
    generatedClusterConstraint,
  };

  const { data: automatedCustomCluster } = useQuery({
    enabled:
      heatmapData != null &&
      averagedHeatmapData != null &&
      coreCustomCluster != null,
    queryKey: ["automatedCustomClusterHeatmap", automatedCustomClusterQueryKey],
    queryFn: () => {
      if (
        heatmapData == null ||
        averagedHeatmapData == null ||
        coreCustomCluster == null
      ) {
        return null;
      }

      const filtered = averagedHeatmapData.filter(
        (heatmapRecord) => heatmapRecord.propertyCount > 0,
      );

      const filteredGridPropertyIds = new Set(
        filtered
          .filter((grid) => Math.abs(grid.pct_diff) < 3)
          .flatMap((x) => x.propertyIds),
      );

      const coreCustomClusterPropertiesIds = new Set(
        coreCustomClusterProperties.properties.map(
          (property) => property.mls_id.listing_number,
        ),
      );

      const propertiesWithin3pct = heatmapData.properties
        .filter((property) =>
          filteredGridPropertyIds.has(property.listing_number),
        )
        .filter(
          (property) =>
            !coreCustomClusterPropertiesIds.has(property.listing_number),
        );

      const automaticPoints = createPointsBoundFromProperties(
        propertiesWithin3pct.map((property) => ({
          lat: property.latitude,
          lng: property.longitude,
        })),
      );

      const points = generatedClusterConstraint ?? automaticPoints;

      const filters = customClusterFilterFromProperties(propertiesWithin3pct);

      const automatedCustomCluster: SessionCustomCluster = {
        id: `generated-custom-cluster-from-${coreCustomCluster.id}`,
        type: SessionCustomClusterType.Generated,
        definition: {
          points,
          filters: [filters],
        },
      };

      return automatedCustomCluster;
    },
  });

  return automatedCustomCluster;
}

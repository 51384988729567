import { CustomClusterBackendFilterPayload } from "~/customClusters/domain/CustomClusterBackend";
import { CustomClusterFilter } from "~/customClusters/domain/CustomClusterFilter";
import { filtersToBackendFilters } from "./filtersToBackendFilters";
import { andOperator } from "./operators";

export function filtersToBackendFilterPayload(
  filters: CustomClusterFilter[],
): CustomClusterBackendFilterPayload {
  return andOperator(filtersToBackendFilters(filters));
}

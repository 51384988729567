import { useLoaderData } from "@remix-run/react";
import * as Sentry from "@sentry/remix";
import { createContext, useEffect } from "react";
import { loader } from "src/app/root";

import { User } from "../user";

interface UserContextValue {
  user: User | undefined;
}

export const UserContext = createContext<UserContextValue>({
  user: undefined,
});

interface UserProviderProps {
  children: React.ReactNode;
}

export function UserProvider({ children }: UserProviderProps) {
  const loaderData = useLoaderData<typeof loader>() as { user: User };

  const user = loaderData?.user;

  useEffect(() => {
    if (user) {
      Sentry.setUser({
        email: user.profile._json?.email,
      });
    } else {
      Sentry.setUser(null);
    }
  }, [user]);

  return (
    <UserContext.Provider value={{ user }}>{children}</UserContext.Provider>
  );
}

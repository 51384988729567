import { Box, SxProps, Theme } from "@mui/material";

interface BoxCenterProps {
  children: React.ReactNode;
  sx?: SxProps<Theme>;
}

export default function BoxCenter({ children, sx }: BoxCenterProps) {
  return (
    <Box
      sx={{
        position: "relative",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        ...sx,
      }}
    >
      {children}
    </Box>
  );
}

import { HeatmapRecord } from "~/boundaries/domain/Heatmap/HeatmapRecord";

export function convertPercentagesToRelativeOfSubjectProperty(
  records: HeatmapRecord[],
  subjectLatitude: number,
  subjectLongitude: number,
): HeatmapRecord[] {
  const closestRecord = records.reduce(
    (minRecord, currentRecord) => {
      // Calculate the Manhattan distance between the current record and the subject
      const currentDistance =
        Math.abs(currentRecord.latitude - subjectLatitude) +
        Math.abs(currentRecord.longitude - subjectLongitude);

      if (!minRecord || currentDistance < minRecord.distance) {
        return { record: currentRecord, distance: currentDistance };
      }

      return minRecord;
    },
    null as { record: HeatmapRecord; distance: number } | null,
  );

  // If no closest record was found (would only happen if the input list was empty)
  // return the original records
  if (!closestRecord) return records;

  const adjustment = closestRecord.record.pct_diff;

  return records.map((record) => ({
    latitude: record.latitude,
    longitude: record.longitude,
    pct_diff: record.pct_diff - adjustment,
  }));
}

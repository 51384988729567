import { Button } from "@mui/material";
import { useSetAtom } from "jotai";

import { featureWeightsModalOpenAtom } from "../../state/featureWeightsModalState";

export default function FeatureWeightsModalButton() {
  const setFeatureWeightsModalOpen = useSetAtom(featureWeightsModalOpenAtom);

  return (
    <Button
      variant="contained"
      onClick={() => setFeatureWeightsModalOpen(true)}
    >
      Modify Feature Ratings
    </Button>
  );
}

import { atom } from "jotai";

import { Coordinate } from "~/subjectProperty/domain/Coordinate";

export const _generatedClusterConstraintAtom = atom<Coordinate[] | null>(null);

export const generatedClusterConstraintAtom = atom((get) =>
  get(_generatedClusterConstraintAtom),
);

export const constrainGeneratedClusterAtom = atom(
  null,
  (get, set, points: Coordinate[]) => {
    set(_generatedClusterConstraintAtom, points);
  },
);

export const unconstrainGeneratedClusterAtom = atom(null, (get, set) => {
  set(_generatedClusterConstraintAtom, null);
});

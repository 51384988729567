import { axiosClient } from "src/modules/api/AxiosClient";
import { useUserToken } from "src/modules/auth/hooks/useUserToken";

import { filtersToBackendFilterPayload } from "~/customClusters/util/backend/filtersToBackendFilterPayload";
import { CustomClusterDefinition } from "../../customClusters/domain/CustomClusterDefinition";
import { coordinateToPolygonFormat } from "../../customClusters/domain/CustomClusterPolygons";
import { useSubjectProperty } from "../../subjectProperty/state/useSubjectProperty";
import { HeatmapProperty } from "../domain/Heatmap/HeatmapProperty";
import { HeatmapRecord } from "../domain/Heatmap/HeatmapRecord";

interface HeatmapFnOptions {
  customCluster: CustomClusterDefinition | undefined;
  region: string;
  resolution: number;
  filterFactor: number;
  desiredHomeSales: number;
}

interface HeatmapRequestResult {
  properties: HeatmapProperty[];
  grid: HeatmapRecord[];
}

interface HeatmapResult {
  resolution: number;
  heatmap: HeatmapRecord[];
  properties: HeatmapProperty[];
}

const heatmapFn = async (
  options: HeatmapFnOptions,
  token: string,
): Promise<HeatmapResult> => {
  if (options.customCluster == null) {
    return {
      resolution: 1,
      heatmap: [],
      properties: [],
    };
  }

  const polygonFormat = coordinateToPolygonFormat(options.customCluster.points);
  const backendFilters = filtersToBackendFilterPayload(
    options.customCluster.filters,
  );

  const requestBody = {
    custom_cluster: {
      polygon: polygonFormat,
      filters: backendFilters,
    },
    resolution: options.resolution,
    filter_factor: options.filterFactor,
    desired_home_sales: options.desiredHomeSales,
  };

  const { data } = await axiosClient.post<HeatmapRequestResult>(
    `/customclusters/${options.region}/location_adjustments`,
    requestBody,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

  return {
    resolution: options.resolution,
    heatmap: data.grid,
    properties: data.properties,
  };
};

export const useApiHeatmap = (
  providedOptions: Omit<HeatmapFnOptions, "region">,
  enabled = true,
) => {
  const token = useUserToken();
  const { subjectProperty } = useSubjectProperty();
  const options = {
    ...providedOptions,
    region: subjectProperty.region,
  };
  return {
    queryKey: ["heatmap", options],
    queryFn: () => heatmapFn(options, token),
    enabled,
  };
};

export const useApiHeatmapLazyLoad = () => {
  const token = useUserToken();
  const { subjectProperty } = useSubjectProperty();

  return function (providedOptions: Omit<HeatmapFnOptions, "region">) {
    const options = {
      ...providedOptions,
      region: subjectProperty.region,
    };
    return {
      queryKey: ["heatmap", options],
      queryFn: () => heatmapFn(options, token),
      enabled: false,
    };
  };
};

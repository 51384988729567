import { atom } from "jotai";
import sortBy from "lodash/sortBy";

import { MlsId } from "../../propertyListing/domain/MlsId";
import { parsePropertyList } from "../utils/PropertyListParser";
import { adjustmentsPropertyListModalStateAtom } from "./adjustmentsPropertyListModalState";

export const _adjustmentsPropertyListAtom = atom<MlsId[]>([]);

export const readAdjustmentsPropertyListAtom = atom((get) =>
  get(_adjustmentsPropertyListAtom),
);

export const writeAdjustmentsPropertyListAtom = atom(
  null,
  (get, set, newMlsIdString: string, mlsName: string) => {
    const propertyList = parsePropertyList(newMlsIdString);
    const propertyMlsIdList = propertyList.map((mlsNumber) => ({
      mls_name: mlsName,
      listing_number: mlsNumber.toString(),
    }));

    const oldPropertyList = get(_adjustmentsPropertyListAtom);
    const otherMlsProperties = oldPropertyList.filter(
      (x) => x.mls_name !== mlsName,
    );

    const combinedPropertyList = [...otherMlsProperties, ...propertyMlsIdList];

    const sortedPropertyList = sortBy(
      combinedPropertyList,
      (x) => x.listing_number,
    );

    set(_adjustmentsPropertyListAtom, sortedPropertyList);
  },
);

export const clearAdjustmentsPropertyListAtom = atom(null, (get, set) => {
  set(_adjustmentsPropertyListAtom, []);
  set(adjustmentsPropertyListModalStateAtom, true);
});

export const adjustmentsPropertyListCountAtom = atom(
  (get) => get(_adjustmentsPropertyListAtom).length,
);

export const adjustmentsDefinedAtom = atom(
  (get) => get(adjustmentsPropertyListCountAtom) > 0,
);

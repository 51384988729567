import { useAtomValue, useSetAtom } from "jotai";
import { useState } from "react";

import {
  readSubjectPropertyAtom,
  writeSubjectPropertyAtom,
} from "../../state/subjectPropertyState";
import {
  SubjectProperty,
  SubjectPropertyKeys,
} from "~/common/hooks/useSessionData";

export type SubjectPropertyModalChanges = Record<
  SubjectPropertyKeys,
  string | number
>;

const initialSubjectProperty = {
  address: undefined,
  gla: undefined,
  lot_sqft: 0,
  bedrooms: 0,
  basement_finished_sqft: 0,
  garage_spaces: 0,
  year_built: 0,
  longitude: undefined,
  latitude: undefined,
  region: undefined,
  full_bathrooms: 0,
  half_bathrooms: 0,
};

export const useSubjectPropertyModalChanges = () => {
  const [changes, setChanges] = useState<SubjectProperty>(
    {} as SubjectProperty,
  );
  const writeSubjectProperty = useSetAtom(writeSubjectPropertyAtom);
  const subjectProperty = useAtomValue(readSubjectPropertyAtom);

  const addChange = (
    feature: SubjectPropertyKeys,
    newValue: string | number,
  ) => {
    writeSubjectProperty({ ...subjectProperty, [feature]: newValue });
  };

  const clearChanges = () => {
    writeSubjectProperty(initialSubjectProperty);
    setChanges({} as SubjectProperty);
  };

  return {
    changes,
    addChange,
    clearChanges,
  };
};

/* eslint-disable complexity */
import {
  useQuery,
  useMutation,
  useQueryClient,
  queryOptions,
} from "@tanstack/react-query";
import { useUserToken } from "src/modules/auth/hooks/useUserToken";
import { useParams } from "@remix-run/react";

import { createSessionSubject, getSessionSubject } from "../api/sessionService";
import { SessionSubjectResponse } from "../domain/SessionDataTypes";

export const useCreateSessionSubject = () => {
  const queryClient = useQueryClient();
  const params = useParams();
  if (!params.session) {
    throw new Error("Session not found");
  }
  const sessionId: string = params.session;

  const token = useUserToken();

  return useMutation({
    mutationKey: ["createSessionSubject", sessionId],
    mutationFn: (subjectData: SessionSubjectResponse) =>
      createSessionSubject(sessionId, subjectData, token),
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: ["subject", sessionId, { parent: "sessions" }],
      });
    },
  });
};

export const getSessionSubjectOptions = (sessionId: string, token: string) => {
  return queryOptions({
    queryKey: ["subject", sessionId, { parent: "sessions" }],
    queryFn: () => getSessionSubject(sessionId, token),
  });
};

export const useGetSessionSubject = () => {
  const params = useParams();
  const token = useUserToken();

  if (!params.session) {
    console.error("Session not found - useGetSessionSubject");
    throw new Error("Session not found - useGetSessionSubject");
    //return undefined;
  }

  if (!token) {
    throw new Error("Token not found, most likely not authenticated");
  }

  const sessionId: string = params.session;

  return getSessionSubjectOptions(sessionId, token);
};

export const useSessionHasSubject = () => {
  const params = useParams();
  const token = useUserToken();
  const { data, error } = useQuery(useGetSessionSubject());

  if (!params.session) {
    console.error("Session not found - useSessionHasSubject");
    return false;
  }

  if (!token) {
    console.error("Token not found, most likely not authenticated");
    return false;
  }
  if (error) {
    console.error("Error fetching session subject");
    return false;
  }

  if (!data || data === null || data.subject_property === null) {
    return false;
  }
  return true;
};

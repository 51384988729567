import { hookstate, State, useHookstate } from "@hookstate/core";

interface SelectedClustersState {
  selectedClusterIds: Set<number>;
}

const selectedClustersState: State<SelectedClustersState> = hookstate({
  selectedClusterIds: new Set<number>([]),
});

export const useSelectedClusters = () => {
  const state = useHookstate(selectedClustersState);

  const isClusterSelected = (clusterId: number) => {
    return state.selectedClusterIds.value.has(clusterId);
  };

  const selectCluster = (clusterId: number) => {
    state.selectedClusterIds.set((old) => old.add(clusterId));
  };

  const unselectCluster = (clusterId: number) => {
    state.selectedClusterIds.set((old) => {
      const cloned = new Set(old);
      cloned.delete(clusterId);
      return cloned;
    });
  };

  const toggleCluster = (clusterId: number) => {
    state.selectedClusterIds.set((old) => {
      const cloned = new Set(old);

      if (cloned.has(clusterId)) {
        cloned.delete(clusterId);
      } else {
        cloned.add(clusterId);
      }

      return cloned;
    });
  };

  const clearClusters = () => {
    state.selectedClusterIds.set(new Set([]));
  };

  return {
    selectedClusters: state.selectedClusterIds,
    isClusterSelected,
    selectCluster,
    unselectCluster,
    toggleCluster,
    clearClusters,
  };
};

import SubjectPropertySelectionFormField from "./SubjectPropertySelectionFormField";
import SubjectPropertySelectionFormFieldLotSize from "./SubjectPropertySelectionFormFieldLotSize";
import { SubjectPropertyKeys } from "~/common/hooks/useSessionData";

interface SubjectPropertySelectionFormFieldProps {
  feature: SubjectPropertyKeys;
  currentValue: string | number;
  onChange: (newValue: number, feature: SubjectPropertyKeys) => void;
}

interface SubjectPropertySelectionFormFieldSelectorProps
  extends SubjectPropertySelectionFormFieldProps {
  feature: SubjectPropertyKeys;
}

export default function SubjectPropertySelectionFormFieldSelector({
  feature,
  ...props
}: SubjectPropertySelectionFormFieldSelectorProps) {
  if (feature === "lot_sqft") {
    return <SubjectPropertySelectionFormFieldLotSize {...props} />;
  }

  return <SubjectPropertySelectionFormField feature={feature} {...props} />;
}

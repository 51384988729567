import { ClientOnly } from "remix-utils/client-only";
import Gate from "src/modules/ui/components/Gate";

import { useCustomClusters } from "~/customClusters/hooks/useCustomClusters";
import DefinedCustomClustersGateDefaultFallback from "./DefinedCustomClustersGateDefaultFallback";

interface DefinedCustomClustersGateProps {
  children: React.ReactNode;
  fallback?: React.ReactNode;
}

export default function DefinedCustomClustersGate({
  children,
  fallback,
}: DefinedCustomClustersGateProps) {
  const customClusters = useCustomClusters();

  const atLeastOneCustomCluster = customClusters.length > 0;

  return (
    <Gate
      condition={atLeastOneCustomCluster}
      fallback={
        fallback ?? (
          <ClientOnly>
            {() => <DefinedCustomClustersGateDefaultFallback />}
          </ClientOnly>
        )
      }
    >
      {children}
    </Gate>
  );
}

import { Coordinate } from "~/subjectProperty/domain/Coordinate";
import { getBoundsOfPoints } from "./getBoundsOfPoints";

export function getDistanceBetweenPoints(
  points: Coordinate[],
  resolution: number,
): { targetDistanceLat: number; targetDistanceLng: number } {
  const { minLat, maxLat } = getBoundsOfPoints(points);

  const maxLatRads = (maxLat * Math.PI) / 180;
  const minLatRads = (minLat * Math.PI) / 180;

  const targetDistanceLat = (maxLat - minLat) / resolution;
  const targetDistanceLng =
    targetDistanceLat * (1 / Math.cos((maxLatRads + minLatRads) / 2));

  return {
    targetDistanceLat,
    targetDistanceLng,
  };
}

import {
  CustomClusterBackendFilter,
  CustomClusterBackendFilterOrOperator,
  CustomClusterBackendOperator,
  FilterOperatorType,
} from "~/customClusters/domain/CustomClusterBackend";
import { CustomClusterFilter } from "~/customClusters/domain/CustomClusterFilter";
import { andOperator, orOperator } from "./operators";

export function filtersToBackendFilters(
  filters: CustomClusterFilter[],
): CustomClusterBackendFilterOrOperator[] {
  return filters.flatMap((filter) => filterToBackendFilter(filter));
}

// eslint-disable-next-line complexity
export function filterToBackendFilter(
  value: CustomClusterFilter,
): CustomClusterBackendFilterOrOperator[] {
  if (value.range.type === "propertyList") {
    return [createBackendPropertyListFilter(value.range.in)];
  }

  if (value.range.type === "string") {
    return [createBackendStringFilter(value.feature, value.range.value)];
  }

  const featureName = value.feature;
  // If both are null, return no filters
  if (value.range.from === null && value.range.to === null) {
    return [];
  }

  if (value.range.from === null && value.range.to !== null) {
    return [createBackendNumericFilter(featureName, "<=", value.range.to)];
  }

  if (value.range.from !== null && value.range.to === null) {
    return [createBackendNumericFilter(featureName, ">=", value.range.from)];
  }

  if (value.range.from !== null && value.range.to !== null) {
    if (value.range.from > value.range.to) {
      return [];
    }

    // TODO HOTFIX TF-752 remove when fixed
    if (
      value.range.from == 0 &&
      value.range.to == 0 &&
      featureName === "basement_finished_sqft"
    ) {
      return [createBackendNumericFilter(featureName, "<=", 0)];
    }

    if (value.range.from == value.range.to) {
      return [createBackendNumericFilter(featureName, "==", value.range.from)];
    }

    return [
      createBackendNumericFilter(featureName, ">=", value.range.from),
      createBackendNumericFilter(featureName, "<=", value.range.to),
    ];
  }

  return [];
}

function createBackendNumericFilter(
  feature: string,
  operator: FilterOperatorType,
  value: number,
): CustomClusterBackendFilter {
  return {
    filter_type: "SimpleFeatureFilter",
    feature_name: feature,
    operator,
    val: value,
    precision: 2,
  };
}

function createBackendPropertyListFilter(
  values: Record<string, string[]>,
): CustomClusterBackendOperator {
  const mlsFilterPair = Object.entries(values).map(
    ([mlsName, mlsProperties]) => {
      return andOperator([
        {
          filter_type: "SimpleFeatureFilter",
          feature_name: "listing_number",
          operator: "in",
          val: mlsProperties,
        },
        {
          filter_type: "SimpleFeatureFilter",
          feature_name: "mls_name",
          operator: "==",
          val: mlsName,
        },
      ]);
    },
  );
  return orOperator(mlsFilterPair);
}

function createBackendStringFilter(
  feature: string,
  value: string,
): CustomClusterBackendFilter {
  return {
    filter_type: "SimpleFeatureFilter",
    feature_name: feature,
    operator: "==",
    val: value,
  };
}

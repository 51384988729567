import TextField2 from "src/modules/ui/components/TextField2";

import { formatSubjectPropertyModalFeatureLabel } from "../../domain/PropertyDataPoint/PropertyDataPointFeatureFormatting";
import { SubjectPropertyKeys } from "~/common/hooks/useSessionData";
import { stringAsNumber } from "~/common/util/formatters";

interface SubjectPropertySelectionFormFieldProps {
  feature: SubjectPropertyKeys;
  currentValue: string | number;
  onChange: (newValue: number, feature: SubjectPropertyKeys) => void;
}

export default function SubjectPropertySelectionFormField({
  feature,
  currentValue,
  onChange,
}: SubjectPropertySelectionFormFieldProps) {
  const onTextChange = (rawValue: string) => {
    const value = stringAsNumber(rawValue);

    if (value != null) {
      onChange(value, feature);
    }
  };

  const isValueValid = (rawValue: string) => {
    return stringAsNumber(rawValue) != null;
  };

  return (
    <TextField2
      key={feature}
      onValueChange={onTextChange}
      initialValue={currentValue.toString()}
      isValid={isValueValid}
      id={feature}
      required
      label={formatSubjectPropertyModalFeatureLabel(feature)}
      size="small"
    />
  );
}

import { useSuspenseQuery } from "@tanstack/react-query";
import { useAtom } from "jotai";

import { createPointsBoundFromProperties } from "~/boundaries/components/Map/MapView/TileLayer/Heatmap/HeatmapLayer/logic/createPointsBoundFromProperties";
import { useGetSessionSubject } from "~/common/hooks/useSessionSubject";
import { useApiPropertyListingGetByZipcode } from "~/propertyListing/api/propertyListingGetByZipcode";
import {
  SessionCustomCluster,
  SessionCustomClusterType,
} from "../domain/SessionCustomCluster";
import { zipcodeCustomClusterAtom } from "../state/zipcodeCustomClusterState";
import { customClusterFilterFromProperties } from "../util/customClusterFilterFromProperties";

export function useZipcodeCustomCluster() {
  const { data } = useSuspenseQuery(useGetSessionSubject());
  const subjectPropertyZipcode = data.subject_property.address.zipcode;
  const [zipcodeCustomCluster] = useAtom(zipcodeCustomClusterAtom);

  const { data: zipcodeProperties } = useSuspenseQuery(
    useApiPropertyListingGetByZipcode(zipcodeCustomCluster),
  );

  if (subjectPropertyZipcode == null || zipcodeProperties == null) {
    return null;
  }

  const properties = zipcodeProperties.properties;

  const points = createPointsBoundFromProperties(
    properties.map((property) => ({
      lat: property.latitude,
      lng: property.longitude,
    })),
  );

  const filters = customClusterFilterFromProperties(
    properties.map((property) => property.mls_id),
  );

  const automatedCustomCluster: SessionCustomCluster = {
    id: `zipcode-custom-cluster-${subjectPropertyZipcode}`,
    type: SessionCustomClusterType.Zipcode,
    definition: {
      points,
      filters: [filters],
    },
  };

  return automatedCustomCluster;
}

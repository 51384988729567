/* eslint-disable complexity */
import { HeatmapProperty } from "~/boundaries/domain/Heatmap/HeatmapProperty";
import {
  HeatmapRecord,
  HeatmapRecordInGrid,
} from "~/boundaries/domain/Heatmap/HeatmapRecord";
import { getBoundsOfPoints } from "./getBoundsOfPoints";
import { getDistanceBetweenPoints } from "./getDistanceBetweenPoints";

// TODO change to a stable grid
// params:
// initialMaxResolution -> initial bins (large ones)
// as you zoom in, the resolution gets divided by powers of 2
// however, propertyCount gets either distributed to all "parent bins"
// OR we separate visibility from property count, and visibility is distrubuted to all children bins
// OR we do a component which handles zoom level by itself and
// takes care of splitting itself into quadrants

export function averageHeatmapRecords(
  points: HeatmapRecord[],
  targetResolution: number,
  properties: HeatmapProperty[] = [],
): HeatmapRecordInGrid[] {
  if (points.length === 0) return [];

  const coordinates = points.map((p) => ({
    lat: p.latitude,
    lng: p.longitude,
  }));

  const { minLat, minLng } = getBoundsOfPoints(coordinates);
  const { targetDistanceLat, targetDistanceLng } = getDistanceBetweenPoints(
    coordinates,
    targetResolution,
  );

  const latCells = targetResolution;
  const lngCells = targetResolution;

  const grid: {
    sum: number;
    count: number;
    propertyCount: number;
    propertyIds: string[];
  }[][] = Array(latCells)
    .fill(null)
    .map(() =>
      Array(lngCells)
        .fill(null)
        .map(() => ({ sum: 0, count: 0, propertyCount: 0, propertyIds: [] })),
    );

  for (const record of points) {
    const latIndex = Math.floor((record.latitude - minLat) / targetDistanceLat);
    const lngIndex = Math.floor(
      (record.longitude - minLng) / targetDistanceLng,
    );

    if (grid[latIndex] != null && grid[latIndex][lngIndex] != null) {
      grid[latIndex][lngIndex].sum += record.pct_diff;
      grid[latIndex][lngIndex].count++;
    }
  }

  for (const property of properties) {
    const latIndex = Math.floor(
      (property.latitude - minLat) / targetDistanceLat,
    );
    const lngIndex = Math.floor(
      (property.longitude - minLng) / targetDistanceLng,
    );

    if (grid[latIndex] != null && grid[latIndex][lngIndex] != null) {
      grid[latIndex][lngIndex].propertyCount += 1;
      grid[latIndex][lngIndex].propertyIds.push(property.listing_number);
    }
  }

  const averagedRecords: HeatmapRecordInGrid[] = [];
  for (let i = 0; i < latCells; i++) {
    for (let j = 0; j < lngCells; j++) {
      if (grid[i][j].count > 0) {
        const neighborPropertyCount = getNeighborPropertyCount(
          grid,
          i,
          j,
          targetResolution,
        );
        averagedRecords.push({
          latitude: minLat + i * targetDistanceLat,
          longitude: minLng + j * targetDistanceLng,
          pct_diff: grid[i][j].sum / grid[i][j].count,
          propertyCount: neighborPropertyCount + grid[i][j].propertyCount,
          propertyIds: grid[i][j].propertyIds,
          gridX: i,
          gridY: j,
        });
      }
    }
  }

  return averagedRecords;
}
function getNeighborPropertyCount(
  grid: { sum: number; count: number; propertyCount: number }[][],
  i: number,
  j: number,
  resolution: number,
) {
  let sum = 0;
  const baseRange = 3;
  const range = Math.ceil(baseRange * (resolution / 200));

  // Check all neighboring cells
  for (let x = -range; x <= range; x++) {
    for (let y = -range; y <= range; y++) {
      // Skip the current cell
      if (x === 0 && y === 0) continue;

      // Calculate the index of the neighboring cell
      const neighborI = i + x;
      const neighborJ = j + y;

      // Check if the neighboring cell is within the grid boundaries
      if (
        neighborI >= 0 &&
        neighborI < grid.length &&
        neighborJ >= 0 &&
        neighborJ < grid[0].length
      ) {
        sum += grid[neighborI][neighborJ].propertyCount;
      }
    }
  }

  return sum;
}

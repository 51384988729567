import { axiosClient } from "src/modules/api/AxiosClient";
import { useUserToken } from "src/modules/auth/hooks/useUserToken";

import { SessionCustomCluster } from "~/customClusters/domain/SessionCustomCluster";
import { filtersToBackendFilterPayload } from "~/customClusters/util/backend/filtersToBackendFilterPayload";
import { coordinateToPolygonFormat } from "../../customClusters/domain/CustomClusterPolygons";
import { PropertyListing } from "../domain/PropertyListing";
import {
  PropertyListingGetByIdsResponse,
  propertyListingGetByIdsResponseToPropertyListing,
} from "./types/PropertyListingGetByIdsResponse";

interface PropertyListingGetInPolygonFnOptions {
  customClusters: SessionCustomCluster[];
}

export interface PropertyListingGetInPolygonRawResult {
  property_listings: PropertyListingGetByIdsResponse[];
}

export interface PropertyListingGetInPolygonResult {
  customClusterIds: string[];
  properties: PropertyListing[];
}

export const propertyListingGetInPolygonFn = async (
  options: PropertyListingGetInPolygonFnOptions,
  token: string,
  includeMedia: boolean,
): Promise<PropertyListingGetInPolygonResult> => {
  if (options.customClusters.length == 0) {
    return {
      customClusterIds: [],
      properties: [],
    };
  }

  const requestBody = {
    include_media: includeMedia,
    custom_clusters: options.customClusters.map((cluster) => ({
      polygon: coordinateToPolygonFormat(cluster.definition.points),
      filters: filtersToBackendFilterPayload(cluster.definition.filters),
    })),
  };

  const { data } = await axiosClient.post<PropertyListingGetInPolygonRawResult>(
    `/property_listing/get_in_polygon`,
    requestBody,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

  return {
    customClusterIds: options.customClusters.map((cluster) => cluster.id),
    properties: data.property_listings.map((x) =>
      propertyListingGetByIdsResponseToPropertyListing(x),
    ),
  };
};

export const useApiPropertyListingGetInPolygon = (
  customClusters: (SessionCustomCluster | undefined)[],
  includeMedia = false,
) => {
  const token = useUserToken();

  const options = {
    customClusters: customClusters.filter((x) => x != null),
  };

  return {
    queryKey: ["propertyListingGetInPolygon", options, includeMedia],
    queryFn: () => propertyListingGetInPolygonFn(options, token, includeMedia),
    enabled: customClusters.length > 0,
  };
};

export const useApiPropertyListingGetInPolygonMultipleClustersQueries = (
  customClusters: SessionCustomCluster[],
  includeMedia = false,
) => {
  const token = useUserToken();

  return customClusters.map((customClusterEntry) => {
    const options = {
      customClusters: [customClusterEntry],
    };
    return {
      queryKey: ["propertyListingGetInPolygon", options, includeMedia],
      queryFn: () =>
        propertyListingGetInPolygonFn(options, token, includeMedia),
    };
  });
};

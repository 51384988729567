export function formatNumber(number: number) {
  return new Intl.NumberFormat("en-US").format(number);
}

export function stringAsNumber(rawValue: string): number | undefined {
  const value = parseFloat(rawValue.replaceAll(",", ""));

  if (isNaN(value)) {
    return undefined;
  }

  return value;
}

export function formatCurrency(amount: number, decimals = 0) {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
    maximumFractionDigits: decimals,
  }).format(amount);
}

export function currencyAsNumber(amount: string) {
  if (typeof amount === "number") {
    return amount;
  }

  const value = amount.replaceAll("$", "").replaceAll(",", "");
  const valueAsNumber = parseFloat(value);

  if (isNaN(valueAsNumber)) {
    return undefined;
  }

  return valueAsNumber;
}

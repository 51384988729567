import { useParams } from "@remix-run/react";
import Gate from "src/modules/ui/components/Gate";

interface TrueTractsSubjectPropertyGateProps {
  children: React.ReactNode;
  fallback?: React.ReactNode;
  displayModal?: boolean;
}

export default function SessionGate({
  children,
  fallback = <div>Session not found</div>,
  displayModal = false,
}: TrueTractsSubjectPropertyGateProps) {
  const params = useParams();

  return (
    <Gate
      condition={params.session ? true : false}
      fallback={displayModal ? fallbackWithModal(fallback) : fallback}
    >
      {children}
    </Gate>
  );
}

const fallbackWithModal = (fallback: React.ReactNode) => fallback;

import { StandardTextFieldProps, TextField } from "@mui/material";
import { startTransition, useEffect, useState } from "react";

interface TextField2Props extends StandardTextFieldProps {
  initialValue: string | undefined;
  onValueChange: (newValue: string) => void;
  isValid?: (value: string) => boolean;
}

export default function TextField2({
  initialValue,
  onValueChange,
  isValid = () => true,
  ...textFieldProps
}: TextField2Props) {
  const [fieldValue, setFieldValue] = useState<string>(initialValue ?? "");

  useEffect(
    function updateFieldValue() {
      startTransition(() => {
        setFieldValue(initialValue ?? "");
      });
    },
    [initialValue],
  );

  const onTextChange = (newValue: string) => {
    setFieldValue(newValue);

    if (isValid(newValue)) {
      onValueChange(newValue);
    }
  };

  const isValueValid = isValid(fieldValue);

  return (
    <TextField
      onChange={(e) => onTextChange(e.target.value)}
      value={fieldValue}
      error={!isValueValid}
      {...textFieldProps}
    />
  );
}

import groupBy from "lodash/groupBy";

import { CustomClusterFilter } from "../domain/CustomClusterFilter";

interface PropertyWithMlsId {
  mls_name: string;
  listing_number: string;
}

export function customClusterFilterFromProperties(
  properties: PropertyWithMlsId[],
): CustomClusterFilter {
  const groupedMlsesDictionary = groupBy(properties, (x) => x.mls_name);

  const groupedMlsesList = Object.entries(groupedMlsesDictionary).map(
    ([mlsName, mlsProperties]) => {
      return [mlsName, mlsProperties.map((x) => x.listing_number)] as [
        string,
        string[],
      ];
    },
  );

  const groupedMlses = Object.fromEntries(groupedMlsesList);

  return {
    feature: "listing_number",
    range: {
      type: "propertyList",
      in: groupedMlses,
    },
  };
}

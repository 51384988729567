import { atom } from "jotai";

import { SubjectProperty } from "../../common/hooks/useSessionData";

const initialSubjectProperty: SubjectProperty = {
  address: undefined,
  gla: undefined,
  lot_sqft: undefined,
  bedrooms: undefined,
  bathrooms: undefined,
  basement_finished_sqft: undefined,
  garage_spaces: undefined,
  year_built: undefined,
  longitude: undefined,
  latitude: undefined,
  region: undefined,
  full_bathrooms: undefined,
  half_bathrooms: undefined,
};

export const _subjectPropertyAtom = atom<SubjectProperty>(
  initialSubjectProperty,
);

export const readSubjectPropertyAtom = atom((get) => get(_subjectPropertyAtom));

export const writeSubjectPropertyAtom = atom(
  null,
  (get, set, newSubjectProperty: SubjectProperty) => {
    set(_subjectPropertyAtom, newSubjectProperty);
  },
);

import { axiosClient } from "src/modules/api/AxiosClient";

import {
  SessionSubjectProperty,
  SessionSubjectResponse,
} from "../domain/SessionDataTypes";

interface CreateSessionResponse {
  id: string;
}

export interface GetSessionResponse {
  session: {
    id: string;
    name?: string | null;
  };
  subject_id?: string | null;
  created_by_id: string;
  feature_weights_id: string;
  active_static_clusters: string[];
  created_at: string;
  updated_at: string;
  session_status?: string | null;
  session_step?: string | null;
  session_effective_date?: string | null; // if not provided then it should be same as session_created_at
}

interface GetSessionsResponse {
  id: string;
  name: string;
  created_at: string;
  updated_at: string;
  session_status: string;
  session_step: string;
  session_effective_date: string;
}

interface GetSessionsPaginatedResponse {
  sessions: GetSessionsResponse[];
  pagination_metadata: {
    total_pages: number;
    total_count: number;
    page: number;
    size: number;
  };
}

interface GetSessionSubjectResponse {
  subject_property: SessionSubjectProperty;
}

export const createSession = async (
  token: string,
): Promise<CreateSessionResponse> => {
  const response = await axiosClient.post<CreateSessionResponse>(
    "/session",
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return response.data;
};

export const getSession = async (
  sessionId: string,
  token: string,
): Promise<GetSessionResponse> => {
  const response = await axiosClient.get<GetSessionResponse>(
    `/session/${sessionId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return response.data;
};

export const getSessions = async (
  token: string,
  pagination = { page: 1, size: 20 },
): Promise<GetSessionsPaginatedResponse> => {
  const response = await axiosClient.get<GetSessionsPaginatedResponse>(
    "/sessions",
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        page: pagination.page,
        size: pagination.size,
      },
    },
  );
  //Remapping of session data to ensure data follows the format expected by the frontend
  response.data.sessions.forEach((session) => {
    session.session_effective_date = session.created_at;
    session.session_status = "created";
    session.session_step = "subject_property";
  });
  return response.data;
};

export const getSessionSubject = async (
  sessionId: string,
  token: string,
): Promise<GetSessionSubjectResponse> => {
  const response = await axiosClient.get<GetSessionSubjectResponse>(
    `/session/${sessionId}/subject`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return response.data;
};

export const createSessionSubject = async (
  sessionId: string,
  subjectData: SessionSubjectResponse,
  token: string,
): Promise<SessionSubjectResponse> => {
  const response = await axiosClient.post<SessionSubjectResponse>(
    `/session/${sessionId}/subject`,
    subjectData,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return response.data;
};

export const updateSessionSubject = async (
  sessionId: string,
  subjectData: Partial<SessionSubjectResponse>,
): Promise<SessionSubjectResponse> => {
  const response = await axiosClient.put<SessionSubjectResponse>(
    `/session/${sessionId}/subject`,
    subjectData,
  );
  return response.data;
};

export const deleteSessionSubject = async (
  sessionId: string,
): Promise<string> => {
  const response = await axiosClient.delete<string>(
    `/session/${sessionId}/subject`,
  );
  return response.data;
};

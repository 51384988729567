import { Stack, SxProps } from "@mui/material";
import TextField2 from "src/modules/ui/components/TextField2";

import { SubjectPropertyKeys } from "~/common/hooks/useSessionData";
import { stringAsNumber } from "~/common/util/formatters";
const acToSqft = 43560;
const feature: SubjectPropertyKeys = "lot_sqft";

interface SubjectPropertySelectionFormFieldLotSizeProps {
  currentValue: string | number;
  onChange: (newValue: number, feature: SubjectPropertyKeys) => void;
  sx?: SxProps;
}

export default function SubjectPropertySelectionFormFieldLotSize({
  currentValue,
  onChange,
  sx,
}: SubjectPropertySelectionFormFieldLotSizeProps) {
  const lotSize = parseLotSizeInput(currentValue);

  const isValueValid = (rawValue: string) => {
    return stringAsNumber(rawValue) != null;
  };

  const onTextChangeSqft = (rawValue: string) => {
    const sqft = stringAsNumber(rawValue);

    if (sqft != null) {
      onChange(sqft, feature);
    }
  };

  const onTextChangeAc = (rawValue: string) => {
    const ac = stringAsNumber(rawValue);

    if (ac != null) {
      const sqft = Math.round(ac * acToSqft);
      onChange(sqft, feature);
    }
  };

  return (
    <Stack direction="row" spacing={2}>
      <TextField2
        key="sqft"
        onValueChange={onTextChangeSqft}
        initialValue={lotSize.sqft}
        isValid={isValueValid}
        id={feature}
        required
        label={"Lot size (sqft)"}
        size="small"
        sx={sx}
        fullWidth
      />
      <TextField2
        key="ac"
        onValueChange={onTextChangeAc}
        initialValue={lotSize.ac}
        isValid={isValueValid}
        id={feature}
        required
        label={"Lot size (acres)"}
        size="small"
        sx={sx}
        fullWidth
      />
    </Stack>
  );
}

function parseLotSizeInput(featureValue: string | number) {
  if (featureValue === "") {
    return {
      sqft: "",
      ac: "",
    };
  }

  const sqft = Math.round(parseFloat(featureValue.toString()));
  const ac = Math.round((sqft / acToSqft) * 100) / 100;

  return {
    sqft: sqft.toString(),
    ac: ac.toString(),
  };
}

import DefinedCustomClustersGate from "~/customClusters/components/Gate/DefinedCustomClustersGate";
import SessionGate from "~/sessions/components/SessionGate";
import SubjectPropertyGate from "~/subjectProperty/components/SubjectPropertyGate/SubjectPropertyGate";
import SplitButton from "./SplitButton";

export default function ExportButton() {
  return (
    <SessionGate displayModal={false}>
      <SubjectPropertyGate displayModal={false}>
        <DefinedCustomClustersGate fallback={""}>
          <SplitButton />
        </DefinedCustomClustersGate>
      </SubjectPropertyGate>
    </SessionGate>
  );
}

import { invariant } from "@epic-web/invariant";
import axios from "axios";

const apiBaseUrl = import.meta.env.PUBLIC_API_URL as string;

invariant(apiBaseUrl, "must define PUBLIC_API_URL");

export const axiosClient = axios.create({
  baseURL: apiBaseUrl,
  timeout: 30 * 1000,
  headers: { "Content-Type": "application/json" },
});

axiosClient.interceptors.response.use(
  (response) => response,
  (error) => {
    console.error("error", error);
    return Promise.reject(error);
  },
);

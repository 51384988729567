/* eslint-disable max-lines-per-function */
import pdfMake from "pdfmake/build/pdfmake";
import { Content, TDocumentDefinitions } from "pdfmake/interfaces";

import { CustomClusterFilter } from "~/customClusters/domain/CustomClusterFilter";
import { Address } from "~/reconciliation/domain/types";
import { EXPORT_PDF_FONTS, SUBJECT_MAP_MARKER } from "./exportResources";
import { createFiltersList } from "./utils/filterList";
import {
  createAddressLine,
  createHeading,
  createKeyColumn,
  createKeyColumnWithImage,
  createParagraph,
} from "./utils/generators";

// Types
export interface PDFImageObject {
  image: string;
  width?: number;
  height?: number;
  absolutePosition?: { x: number; y: number };
  type?: string;
}

export interface CreateExportDocumentProps {
  maps: PDFImageObject[];
  filters: CustomClusterFilter[];
  address: Address;
}

export function createExportDocument({
  maps,
  filters,
  address,
}: CreateExportDocumentProps): void {
  pdfMake.fonts = EXPORT_PDF_FONTS;

  const content: Content[] = [
    createHeading("Data-Driven Market Area Analysis"),
    " ",
    createAddressLine(address),
    " ",
    " ",
    createParagraph(
      "The appraiser used a data-driven approach to analyze and define the subject's market area, utilizing an analytical tool powered by machine learning. This tool groups properties into clusters based on similar relationships between home features and sale prices, independent of city, zip code, or census boundaries—eliminating any potential for arbitrary biases. Using this data, the appraiser defined the geographic boundaries of the subject's Core Submarket and applied filters to features such as GLA and lot size to ensure that only properties that directly compete are included in the dataset.",
    ),
    " ",
    ...createFiltersList(filters),
    " ",
    createParagraph(
      "Next, the tool trains a statistical model on the home sales in this appraiser-defined Core Submarket, and then applies this model to all surrounding home sales that lie within the specified filters. The tool controls (adjusts) for date of sale and feature differences (GLA, lot size, baths, etc) first, in order to isolate and identify any locational value differences. This process helps the appraiser identify similar competing submarkets (green) to find additional Comps, as well as any areas where location adjustments may be needed (blue or red). If a Comp from a blue or red zone is used, the appraiser can rely on the tool's output to make a data-driven location adjustment.",
    ),
    " ",
    createParagraph("The heat map below visually represents this data: "),
    ...maps,
    " ",
    { text: "Map Legend", bold: true, fontSize: 18, alignment: "center" },
    " ",
    createKeyColumn({
      text: "= Each black dot represents a home sale within the last 5 years that lies within the filters specified by the appraiser. (Note: Since the appraiser is focused on more recent sales (last 12 months), not all dots are relevant Comps for this assignment.)",
      canvas: { type: "ellipse", x: 5, y: 7, r1: 5, r2: 5, color: "black" },
    }),
    " ",
    createKeyColumnWithImage({
      text: "= Subject Property",
      image: SUBJECT_MAP_MARKER,
    }),
    " ",
    createKeyColumn({
      text: "= Solid black polygon is the Subject's Core Submarket",
      canvas: {
        type: "polyline",
        points: [
          { x: 0, y: 10 },
          { x: 6, y: 12 },
          { x: 10, y: 10 },
          { x: 8, y: 2 },
          { x: 2, y: 4 },
        ],
        closePath: true,
        lineColor: "black",
        lineWidth: 1,
      },
    }),
    " ",
    createKeyColumn({
      text: "= Similar location (<3%). Competing submarket. No location adjustments to Comps necessary.",
      canvas: {
        type: "rect",
        x: 0,
        y: 2,
        w: 10,
        h: 10,
        r: 0,
        color: "darkgreen",
      },
    }),
    " ",
    createKeyColumn({
      text: "= Similar location (3-6%). Competing submarket. Location adjustment to Comps may or may not be necessary.",
      canvas: {
        type: "rect",
        x: 0,
        y: 1,
        w: 10,
        h: 10,
        r: 0,
        color: "lightgreen",
      },
    }),
    " ",
    createKeyColumn({
      text: "= Homes sell for less (-6% or more) after adjusting for feature differences. Positive location adjustments may be needed. The darker the blue, the less expensive the location is.",
      canvas: { type: "rect", x: 0, y: 1, w: 10, h: 10, r: 0, color: "blue" },
    }),
    " ",
    createKeyColumn({
      text: "= Homes sell for more (+6% or more) after adjusting for feature differences. Negative location adjustments may be needed. The darker the red, the more expensive the location is.",
      canvas: { type: "rect", x: 0, y: 1, w: 10, h: 10, r: 0, color: "red" },
    }),
    " ",
    createKeyColumn({
      text: "= No home sales within the past 5 years that lie within the filters specified by the appraiser.",
      canvas: {
        type: "rect",
        x: 0,
        y: 1,
        w: 10,
        h: 10,
        r: 0,
        color: "white",
        lineWidth: 1,
        lineColor: "black",
      },
    }),
  ];

  const docDefinition: TDocumentDefinitions = {
    content,
    images: {
      mapMarkerSubject: SUBJECT_MAP_MARKER,
    },
  };

  const fileName = `TrueTracts-export-${address.street_address}-${new Date()
    .toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    })
    .replace(/,/g, "")}.pdf`;

  pdfMake.createPdf(docDefinition).download(fileName);
}

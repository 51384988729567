/* eslint-disable max-lines-per-function */
/* eslint-disable complexity */
import { Button, Stack } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useAtomValue, useSetAtom } from "jotai";

import { useApiRegionMetadata } from "../../api/regionMetadata";
import { subjectPropertyModalOpenAtom } from "../../state/subjectPropertyModalState";
import {
  readSubjectPropertyAtom,
  writeSubjectPropertyAtom,
} from "../../state/subjectPropertyState";
import { regionQueryOptionsFromModalState } from "../../utils/regionQueryOptionsFromModalState";
import { clearAdjustmentsPropertyListAtom } from "~/adjustments/state/adjustmentsPropertyListState";
import { clearFeatureUnitValuesAtom } from "~/adjustments/state/adjustmentsUnitValueOverrideState";
import { setMapLayersToDefaultValuesAtom } from "~/boundaries/state/mapLayerState";
import { useSelectedClusters } from "~/boundaries/state/useSelectedClusters";
import { SessionSubjectResponse } from "~/common/domain/SessionDataTypes";
import { SubjectProperty } from "~/common/hooks/useSessionData";
import { useCreateSessionSubject } from "~/common/hooks/useSessionSubject";
import { deleteAllClustersAtom } from "~/customClusters/state/customClustersState";
import {
  clearCompFeatureAdjustmentsAtom,
  clearCompWeightsAtom,
} from "~/reconciliation/state/reconCompsState";

interface SubjectPropertySelectionModalSubmitButtonProps {
  changes: SubjectProperty;
  clearChanges: () => void;
  onClose?: () => void;
}

export default function SubjectPropertySelectionModalSubmitButton({
  changes,
  clearChanges,
  onClose,
}: SubjectPropertySelectionModalSubmitButtonProps) {
  const subjectProperty = useAtomValue(readSubjectPropertyAtom);
  const writeSubjectProperty = useSetAtom(writeSubjectPropertyAtom);
  const { clearClusters } = useSelectedClusters();
  const deleteAllClusters = useSetAtom(deleteAllClustersAtom);
  const clearAdjustmentsPropertyList = useSetAtom(
    clearAdjustmentsPropertyListAtom,
  );
  const clearFeatureUnitValues = useSetAtom(clearFeatureUnitValuesAtom);
  const clearCompWeights = useSetAtom(clearCompWeightsAtom);
  const clearCompFeatureAdjustments = useSetAtom(
    clearCompFeatureAdjustmentsAtom,
  );
  const setMapLayersToDefaultValues = useSetAtom(
    setMapLayersToDefaultValuesAtom,
  );
  const setSubjectPropertyModalOpen = useSetAtom(subjectPropertyModalOpenAtom);

  const { data: regionMetadata } = useQuery(
    useApiRegionMetadata(
      regionQueryOptionsFromModalState(changes, subjectProperty),
    ),
  );

  if (
    subjectProperty &&
    regionMetadata &&
    regionMetadata.region &&
    !subjectProperty.region
  ) {
    writeSubjectProperty({ ...subjectProperty, ...regionMetadata });
  }

  const isRegionValid = regionMetadata?.isValid === true;

  const saveEnabled =
    (changes["region"] ?? subjectProperty["region"]) != null && isRegionValid;

  const createSessionSubjectMutation = useCreateSessionSubject();

  const onSave = () => {
    if (subjectProperty && changes) {
      writeSubjectProperty({ ...subjectProperty, ...changes });
      const newSubjectProperty = {
        subject_property: {
          gla: subjectProperty.gla,
          lot_sqft: subjectProperty.lot_sqft,
          bedrooms: subjectProperty.bedrooms,
          baths_full: subjectProperty.baths_full,
          baths_half: subjectProperty.baths_half,
          basement_finished_sqft: subjectProperty.basement_finished_sqft,
          garage_spaces: subjectProperty.garage_spaces,
          year_built: subjectProperty.year_built,
          longitude: subjectProperty.longitude,
          latitude: subjectProperty.latitude,
          address: {
            street_address: subjectProperty.address?.split(",")[0],
            city: subjectProperty.address?.split(",")[1],
            state: subjectProperty.address?.split(",")[2].split(" ")[1],
            zipcode: subjectProperty.address?.split(",")[2].split(" ")[2],
          },
        },
      };
      createSessionSubjectMutation.mutate(
        newSubjectProperty as SessionSubjectResponse,
        {
          onSuccess: () => {
            setSubjectPropertyModalOpen(false);
            clearClusters();
            deleteAllClusters();
            clearAdjustmentsPropertyList();
            clearFeatureUnitValues();
            clearCompWeights();
            clearCompFeatureAdjustments();
          },
          onError: (error) => {
            console.error("Error creating session subject", error);
          },
        },
      );
    }

    // TODO "useSelectedClusters.ts:72 Can't perform a React state update on a component that hasn't mounted yet.
    // This indicates that you have a side-effect in your render function that asynchronously later calls tries
    // to update the component. Move this work to useEffect instead"

    setMapLayersToDefaultValues();

    if (onClose) {
      clearChanges();
      onClose();
    }
  };

  return (
    <Stack direction="row" justifyContent="flex-end" spacing={2}>
      {onClose != null && (
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
      )}
      <Button variant="contained" onClick={onSave} disabled={!saveEnabled}>
        Save &amp; classify
      </Button>
    </Stack>
  );
}

import { Stack } from "@mui/material";

import SubjectPropertySelectionAddressFormField from "./SubjectPropertySelectionAddressFormField";
import SubjectPropertySelectionForm from "./SubjectPropertySelectionForm";
import SubjectPropertySelectionModalSubmitButton from "./SubjectPropertySelectionModalSubmitButton";
import SubjectPropertySelectionModalSubmitStatus from "./SubjectPropertySelectionModalSubmitStatus";
import { useSubjectPropertyModalChanges } from "./useSubjectPropertyModalChanges";

interface SubjectPropertySelectionModalContentsProps {
  onClose?: () => void;
}

export default function SubjectPropertySelectionModalContents({
  onClose,
}: SubjectPropertySelectionModalContentsProps) {
  const { changes, addChange, clearChanges } = useSubjectPropertyModalChanges();

  return (
    <Stack spacing={2} marginY={4}>
      <SubjectPropertySelectionAddressFormField
        changes={changes}
        clearChanges={clearChanges}
      />
      <SubjectPropertySelectionModalSubmitStatus changes={changes} />
      <SubjectPropertySelectionForm changes={changes} addChange={addChange} />
      <SubjectPropertySelectionModalSubmitButton
        changes={changes}
        clearChanges={clearChanges}
        onClose={onClose}
      />
    </Stack>
  );
}

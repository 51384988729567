import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

import SubjectPropertySelectionModalContents from "./SubjectPropertySelectionModalContents";

interface SubjectPropertySelectionModalProps {
  open: boolean;
  onClose?: () => void;
}

export default function SubjectPropertySelectionModal({
  open,
  onClose,
}: SubjectPropertySelectionModalProps) {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Home Features</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please input the subject property info:
        </DialogContentText>
        <SubjectPropertySelectionModalContents onClose={onClose} />
      </DialogContent>
    </Dialog>
  );
}

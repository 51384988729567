/// <reference types="google.maps" />
import { invariant } from "@epic-web/invariant";
import { TextField } from "@mui/material";
import { useQuery, useSuspenseQuery } from "@tanstack/react-query";
import { useSetAtom } from "jotai";
import { useEffect } from "react";

import { useApiRegionMetadata } from "../../api/regionMetadata";
import { usePlacesAutocompleteWithCallback } from "../../hooks/usePlacesAutocompleteWithCallback";
import { writeSubjectPropertyAtom } from "../../state/subjectPropertyState";
import { regionQueryOptionsFromModalState } from "../../utils/regionQueryOptionsFromModalState";
import { useGetSessionSubject } from "~/common/hooks/useSessionSubject";
import { SubjectProperty } from "~/common/hooks/useSessionData";
import "./SubjectPropertySelectionAddressFormField.css";

invariant(
  import.meta.env.PUBLIC_GOOGLE_MAPS_KEY as string,
  "must define PUBLIC_GOOGLE_MAPS_KEY",
);

export interface SubjectPropertySelectionAddressFormOutput {
  latitude: number;
  longitude: number;
}

interface SubjectPropertySelectionAddressFormFieldProps {
  changes: SubjectProperty;
  clearChanges: () => void;
}

export default function SubjectPropertySelectionAddressFormField({
  changes,
  clearChanges,
}: SubjectPropertySelectionAddressFormFieldProps) {
  const writeSubjectProperty = useSetAtom(writeSubjectPropertyAtom);
  const { data: subjectProperty } = useSuspenseQuery(useGetSessionSubject());

  const initialAddress =
    changes["address"]?.toString() ??
    subjectProperty?.subject_property?.address?.street_address ??
    "";

  const onAddressChange = (
    inputAddress: string,
    output: SubjectPropertySelectionAddressFormOutput,
  ) => {
    clearChanges();

    const subjectUpdates = {
      address: inputAddress,
      latitude: output.latitude,
      longitude: output.longitude,
      region: changes["region"],
    };

    writeSubjectProperty({ ...subjectProperty, ...subjectUpdates });
  };

  const { ref } = usePlacesAutocompleteWithCallback(onAddressChange);

  const { data: regionMetadata } = useQuery(
    useApiRegionMetadata(
      regionQueryOptionsFromModalState(
        changes,
        subjectProperty as Partial<SubjectProperty>,
      ),
    ),
  );

  const region = regionMetadata?.region;

  useEffect(
    function syncRegionWithUrl() {
      if (region) {
        changes["region"] = region;
        writeSubjectProperty({
          ...subjectProperty,
          region,
        });
      }
    },
    [region],
  );

  return (
    <TextField
      defaultValue={initialAddress}
      id={"autocomplete-input-address"}
      label={"Address"}
      size="small"
      inputRef={ref}
    />
  );
}

import { CanvasElement, Content } from "pdfmake/interfaces";

import { Address } from "~/reconciliation/domain/types";

interface KeyColumnProps {
  text: string;
  color?: string;
  canvas: CanvasElement;
}

interface KeyColumnPropsWithImage {
  text: string;
  color?: string;
  image: string;
}

export const createKeyColumn = ({
  text,
  color = "black",
  canvas,
}: KeyColumnProps): Content => ({
  columns: [
    {
      canvas: [canvas],
      width: 15,
    },
    {
      text: [{ text, color }],
      fontSize: 10,
      width: "*",
    },
  ],
});

export const createKeyColumnWithImage = ({
  text,
  color = "black",
  image,
}: KeyColumnPropsWithImage): Content => ({
  columns: [
    {
      image: image,
      width: 13,
      height: 15,
    },
    {
      text: [{ text, color }],
      fontSize: 10,
      width: "*",
    },
  ],
});

export const createParagraph = (text: string): Content => ({
  text: [text],
});

export const createUnorderedList = (items: string[]): Content => ({
  ul: items,
});

export const createHeading = (text: string): Content => ({
  text,
  fontSize: 20,
  bold: true,
  alignment: "center",
  margin: [0, 0, 0, 0],
});

export const createAddressLine = (address: Address): Content => {
  const addressString = `${address.street_address}, ${address.city}, ${address.state} ${address.zipcode}`;
  return {
    text: [addressString],
    fontSize: 14,
    bold: true,
    color: "red",
    alignment: "center",
  };
};

import { SubjectProperty } from "../../common/hooks/useSessionData";

// eslint-disable-next-line complexity
export function regionQueryOptionsFromModalState(
  changes: SubjectProperty,
  subjectData: Partial<SubjectProperty>,
) {
  const lat = changes["latitude"] ?? subjectData?.latitude ?? undefined;
  const lng = changes["longitude"] ?? subjectData?.longitude ?? undefined;

  if (lat == null || lng == null) {
    return undefined;
  }

  return {
    latitude: parseFloat(lat.toString()),
    longitude: parseFloat(lng.toString()),
  };
}
